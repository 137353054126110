/* eslint-disable */
import React, { useState, useEffect } from "react";
import CommonDropdown from "../../Common/CommonDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HOME_URL, API_URL, LOGOUT_API_URL, MEMBER_DASHBOARD, MY_ACCOUNT, PRIVACY_POLICY, TERMS_CONDITIONS, PROMOTIONAL_RULES, CONTACT } from "../constant";
import { useSelector } from "react-redux";
import LogoutIcon from "../../../assets/img/logout.png";
import { getAccessToken, checkLogin, getUser, getRegioLcTime } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";

const Header = (props) => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { setSignUp, setLoginSigupUp, setLocationGet } = props;
    const [ Dropdwon, setDropdwon ] = useState(false);
    const LocationUrl = useLocation();
    const { profiledata, accessToken, is_login } = useSelector((state) => state.allReducers);

    useEffect(() => {
        if(LocationUrl.pathname) {
            setLocationGet(LocationUrl.pathname);
        }
    }, [ LocationUrl ]);
    
    useEffect(() => {   
        if(LocationUrl.pathname === HOME_URL) {
            document.title = "Online Sweepstakes Cash | Sweeps Coins | Fun & Interactive Cash ";
        } else if (LocationUrl.pathname === PRIVACY_POLICY) {
            document.title = "Privacy Policy Statement | Sweeps Coins";
        } else if (LocationUrl.pathname === TERMS_CONDITIONS) {
            document.title = "Terms & Conditions | Sweeps Coins ";
        } else if (LocationUrl.pathname === PROMOTIONAL_RULES) {
            document.title = "Official Promotion Rules | Sweeps Coins";
        } else if (LocationUrl.pathname === CONTACT) {
            document.title = "Contact | Sweeps Coins";
        } else if (LocationUrl.pathname === MEMBER_DASHBOARD) {
            document.title = "Member Dashboard";
        } else if (LocationUrl.pathname === MY_ACCOUNT) {
            document.title = "Contact | Sweeps Coins";
        } 
    }, [ LocationUrl.pathname ]);


    const openRespSidebar = () => {
        var element = document.getElementById("responsiveSidebarMain");
        element.classList.toggle("active");
    };

    // logout function
    const Logout = () => {
        window.axios.get(LOGOUT_API_URL, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setTimeout(() => {
                var element = document.getElementById("responsiveSidebarMain");
                element.classList.remove("active");
                Navigate(HOME_URL)
            }, 200);
            localStorage.removeItem("starttime");
            localStorage.removeItem("targettime");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("access_tokens");
            localStorage.setItem("SAVE_CARD", "FALSE");
            dispatch(getAccessToken(""));
            dispatch(checkLogin("no"));
            dispatch(getRegioLcTime(""));
            toast.success(result.data.msg);
        })  
        .catch(function (result) {
            // toast.error(result.response.data.detail);
            setDropdwon(false);
            // if(result.response.data.detail === "Token expired.") {
                AccessTokenCheckLogout();
            // } else {
            //     AccessTokenCheckLogout();
            // }
        });
    };

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() => {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }, 200);
        setTimeout(() =>  Navigate(HOME_URL), 200);
        localStorage.removeItem("accessToken");
        localStorage.setItem("SAVE_CARD", "FALSE");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    return(<header className="Header_section">
        <div className="container container_header container_header_Nav">
            <div className="row">
                <div className="col-md-12">
                    <div className="logogheader">
                        <Link to={HOME_URL} >
                            <h1>Sweeps Coins Rewards</h1>
                            {screen.width < 800 && (<span>Claim Your Free Entries And Play To Earn Cash!</span>)}
                        </Link>
                        {/* {(accessToken === "" && is_login === "no") ? (<div className="userdropdownset">
                            <div className="loginWraps" onClick={() => setSignUp(true)}><svg data-bbox="0 0 50 50" data-type="shape" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g><path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"></path></g></svg>Log In</div>
                        </div>) : (<React.Fragment>
                            {screen.width > 700 ? (<div className="userdropdownset">
                                <div className="loginWraps" onClick={() => setDropdwon(!Dropdwon)}>
                                    {profiledata?.user_avatar_path === null ? (<svg data-bbox="0 0 50 50" data-type="shape" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g><path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"></path></g></svg>) : 
                                    <img src={profiledata?.user_avatar_path} />}
                                    {profiledata?.email?.split("@")[0]}
                                    <svg className="dropdousericon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g><g><polygon points="13,20.4 0,7.4 1.8,5.6 13,16.8 24.2,5.6 26,7.4   "></polygon></g></g></svg>
                                </div>
                                {Dropdwon && (<CommonDropdown setDropdwon={setDropdwon} accessToken={accessToken} />)}
                            </div>) : (<div className="togglebuttonrespo" onClick={() => openRespSidebar()}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>)}
                        </React.Fragment>)} */}

                        {screen.width > 800 ? (<React.Fragment>
                            {(accessToken === "" && is_login === "no") ? (<div className="userdropdownset beforeloginuserDrop">
                                <div className="loginWraps" onClick={() => setLoginSigupUp(true)}><svg data-bbox="0 0 50 50" data-type="shape" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g><path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"></path></g></svg>Log In</div>
                                <div className="loginWraps signupbtnnew" onClick={() => setSignUp(true)}>
                                    {/* <svg data-bbox="0 0 50 50" data-type="shape" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g><path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"></path></g></svg> */}
                                Sign Up</div>
                            </div>) : (<div className="userdropdownset">
                                <div className="loginWraps" onClick={() => setDropdwon(!Dropdwon)}>
                                    {profiledata?.user_avatar_path === null ? (<svg data-bbox="0 0 50 50" data-type="shape" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g><path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"></path></g></svg>) : 
                                    <img src={profiledata?.user_avatar_path} />}
                                    <span className="usernameheader" title={(profiledata?.first_name !== "" && profiledata?.last_name !== "") ? profiledata?.first_name + " " + profiledata?.last_name : profiledata?.email?.split("@")[0]}>
                                        {/* {profiledata?.email?.split("@")[0]} */}
                                        {(profiledata?.first_name !== "" && profiledata?.last_name !== "") ? profiledata?.first_name + " " + profiledata?.last_name : profiledata?.email?.split("@")[0]}
                                    </span>
                                    <svg className="dropdousericon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g><g><polygon points="13,20.4 0,7.4 1.8,5.6 13,16.8 24.2,5.6 26,7.4   "></polygon></g></g></svg>
                                </div>
                                {Dropdwon && (<CommonDropdown setDropdwon={setDropdwon} accessToken={accessToken} />)}
                            </div>)}
                        </React.Fragment>) : (<div className="togglebuttonrespo" onClick={() => openRespSidebar()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>)}
                        {screen.width > 640 && <>
                        {(accessToken !== "" && is_login !== "no") && (<button type="button" className="logoutbtn" onClick={() => Logout()}>
                            <img src={LogoutIcon} alt="logout"/>
                            Logout
                        </button>)}
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </header>);
}

export default Header;