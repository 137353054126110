import React from "react";
import ErrorIcon from "../../assets/img/error.svg";

const CustomMendotoryMsg = (props) => {
    const { value, label } = props;

    if(label && value === "") {
        return(<React.Fragment>
            <img src={ErrorIcon} className="errorsuccessicon" alt={"icon"} />
            <div className="danger-color">{`${label} cannot be empty`}</div>
        </React.Fragment>)
    } 
}

export default CustomMendotoryMsg;