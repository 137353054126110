/* eslint-disable */
import React, { useEffect, useRef } from "react";
import BannerImage from "../../assets/img/SWEEPSCOINS-Games-trim-image.webp";
import { HOME_URL, MY_ACCOUNT } from "../Shared/constant";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { accountNavigate } from "../../redux/actions";

const BannerSection = () => {
    const { accessToken, is_login } = useSelector((state) => state.allReducers);
    const videoEl = useRef(null);
    const dispatch = useDispatch();

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, [ ]);

    const MyAccountLink = () => {
        dispatch(accountNavigate("my-books"));
    };

    return(<section className="bannerSectionWrp">
        {/* <video
          playsInline
          loop
          muted
          alt="All the devices"
          src={"https://sweepscoinscash.s3.us-east-2.amazonaws.com/background.mp4"}
          type="video/mp4"
          ref={videoEl}
        /> */}

        <video loop muted  ref={videoEl}>
            <source src="https://sweepscoinscash.s3.us-east-2.amazonaws.com/background.mp4" type="video/mp4" />
        </video>

        <div className="bannervideocontent">
            <div className="container container_header">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bannerssections">
                            {screen.width > 700 ? (<h4>Congratulations Sweepstakes Winners!</h4>) : (<Link className="bannerbtn" to={(accessToken !== "" && is_login !== "no") ? MY_ACCOUNT : HOME_URL} onClick={() => (accessToken !== "" && is_login !== "no") && MyAccountLink()} >REDEEM YOUR PRIZE</Link>)}
                            <img src={BannerImage}  al="banner"/>
                        </div>
                        {screen.width > 700 && (
                            <Link 
                                className="bannerbtn" 
                                to={(accessToken !== "" && is_login !== "no") ? MY_ACCOUNT : HOME_URL}
                                onClick={() => (accessToken !== "" && is_login !== "no") && MyAccountLink()}
                            >REDEEM YOUR PRIZE</Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default BannerSection;