/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import FaceBookIcon from "../assets/img/facebook.png";
import GoogleIcon from "../assets/img/google.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LOGIN_API_URL, FORGOT_PASSWORD_API_URL, FACEBOOK_LOGIN_API_URL, USER_VALID_LOGIN_API_URL, SOCIAL_FIREBASE_API_URL, HOME_URL } from "./Shared/constant";
import { useDispatch, useSelector } from "react-redux";
import { getUser, checkLogin, getAccessToken, IsSiteIsBlockCheck, getRegioLcTime, getOverCallingGeoLocation, getUniqueBrowserId, getGeoCoplyMessage, getGeoCoplyLocation, getSpoofingDetection } from "../redux/actions";
import {auth,provider} from "../config/firebaseConfig";
import {authfb,providerfb} from "../config/facebookfirebaseConfig";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useLocation } from "react-router-dom";
import InfoIcon from "../assets/img/information-button.png";

const LoginSignupModal = (props) => {
    const { setLoginSigupUp, setSignUp, setFromBankendSignupDetails, setVerifiedLoginEmail } = props;
    const Navigate = useNavigate(); 
    const Location = useLocation(); 
    const [ LoginSignupSet, setLoginSignupSet ] = useState("Login");
    const [ loginWithForm, setLoginWithForm ] = useState(false);
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\$%\^&\*])(?=.{8,})/;
    const [ showPassword, setShowPassword ] = useState(false);
    const dispatch = useDispatch();
    // const LocationToken = localStorage.getItem("GeoLocationToken");
    const [ loaderStart, setloaderStart ] = useState(false);
    const { geoComplyLocation, UniqueBrowserId } = useSelector((state) => state.allReducers);

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    // signup page function
    const signupFunction = () => {
        setSignUp(true);
        setLoginSigupUp(false);
        setLoginWithForm(false);
    };

    // on enter login function
    const onSubmitLogin = (e) => {
        if(e.keyCode === 13) {
            loginFunction();
        };
    };  
      
    const loginFunction = async () => {
        if(email !== "" && password !== "") {
            if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true) {
                dispatch(getOverCallingGeoLocation(true));
                dispatch(getRegioLcTime(""));
                document.getElementById("pageisLoading").style.display = "flex";
                setloaderStart(true);
                const jsonData = JSON.stringify({ email: email.toLowerCase(), password: password});
                window?.axios?.post(LOGIN_API_URL, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function (result) {
                    dispatch(getUniqueBrowserId(result?.data?.data?.user_id));
                    GetGeoLicenceFunction("Login", result?.data?.data?.user_id, result?.data?.data?.token); 
                })  
                .catch(function (result) {
                    document.getElementById("pageisLoading").style.display = "none";
                    setloaderStart(false);
                    Navigate(HOME_URL);
                    if(result?.response?.data?.error === "User not verified.") {
                        dispatch(getUser(""));
                        dispatch(checkLogin("no"));
                        dispatch(getAccessToken(""));
                        setVerifiedLoginEmail(email);
                        signupFunction();
                    } else {
                        dispatch(getUser(""));
                        dispatch(checkLogin("no"));
                        dispatch(getAccessToken(""));
                    }
                    toast.error(result.response.data.error);
                });
                setErrorEmail("");
                setErrorPassword("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(PasswordRegex.test(password) === false) {
                    setErrorPassword("Please Enter Valid Password");
                } else {
                    setErrorPassword("");
                }
            }
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if (password === "") {
                setErrorPassword("Password cannot be empty");
            } else if(PasswordRegex.test(password) === false) {
                setErrorPassword("Please Enter Valid Password");
            } else {
                setErrorPassword("");
            }
        }
    };
    
    const GetGeoLicenceFunction = async (url, id, token) => {
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            if(result?.status === 200) {
            GetGeoLocate(result?.data?.data?.license, url, id, token);
            dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            toast.error(result?.response?.data?.error);
            document.getElementById("pageisLoading").style.display = "none";
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url, id, token) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(id);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text, id, token);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // location Permissions api
    const LocationPermission = async (text, id, token) => {
        console.log("Login Permission");
        const jsonData = JSON.stringify({ 
        "id": id,
        "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            if(result?.status === 200) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            if(result?.data?.data?.status === true) {
                dispatch(getOverCallingGeoLocation(false));
                loginFunctionGeo(token, text);
                // success code
                dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
                localStorage.removeItem("starttime");
                localStorage.removeItem("targettime");
            }
            dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
            if(result?.data?.data?.status === false) {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                if(result?.data?.data?.spoofing_detection != null){
                    // add spoofing detection popup
                    console.log("spoofing detection");
                }
            }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
            
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };


    // login page function
    const loginFunctionGeo = (token, text) => {
        const jsonData = JSON.stringify({ 
            "token" : token, 
            "location" : text 
        });
        window.axios.post(USER_VALID_LOGIN_API_URL, jsonData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }}).then(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            setloaderStart(false);
            dispatch(getUser(result?.data?.data?.user));
            dispatch(getAccessToken(result?.data?.data?.access_token));
            localStorage.setItem("access_tokens", result?.data?.data?.access_token);
            dispatch(checkLogin("yes"));
            toast.success(result.data.msg);
            setLoginSigupUp(false);
            ClearFields();
        }).catch(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            setloaderStart(false);
            Navigate(HOME_URL);
            if(result?.response?.data?.error === "User not verified.") {
                dispatch(getUser(""));
                dispatch(checkLogin("no"));
                dispatch(getAccessToken(""));
                setVerifiedLoginEmail(email);
                signupFunction();
            } else {
                dispatch(getUser(""));
                dispatch(checkLogin("no"));
                dispatch(getAccessToken(""));
            }
            toast.error(result.response.data.error);
        });
    };

    // create new password function button
    const ForgotPasswordFnct = () => {
        if(email !== "") {
            if(EmailRegex.test(email) === true) {
                const jsonData = JSON.stringify({ email: email.toLowerCase() });
                window.axios.post(FORGOT_PASSWORD_API_URL, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function (result) {
                    toast.success(result.data.msg);
                    setLoginSigupUp(false);
                    ClearFields();
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                });
                setErrorEmail("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
            }
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
        }
    };

    // clear field set
    const ClearFields = () => {
        setTimeout(() => {
            setEmail(""); 
            setPassword("");
            setErrorEmail(""); 
            setErrorPassword("");
            Navigate(HOME_URL);
        }, 500);
    };

    // forgot password page function
    const forgotpassFunction = () => {
        setLoginSignupSet("Forgotpassword");
        setLoginWithForm(false);
    }

    // sign up or login with google 
    const authWithgoogle = (e) => {
        e.preventDefault();
        dispatch(getRegioLcTime(""));
        dispatch(getOverCallingGeoLocation(true));
        document.getElementById("pageisLoading").style.display = "flex";
        signInWithPopup(auth,provider).then((data)=>{
            const jsonData = JSON.stringify({ uid: data?.user?.uid });
            window.axios.post(SOCIAL_FIREBASE_API_URL , jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
                }).then(function (result) {
                    // dispatch(getUser(result?.data?.data?.user));
                    // dispatch(getAccessToken(result?.data?.data?.access_token));
                    // dispatch(checkLogin("yes"));
                    // toast.success(result.data.msg);
                    // Navigate(HOME_URL);
                    // setLoginSigupUp(false);
                    // ClearFields();
                    dispatch(getUniqueBrowserId(result?.data?.data?.user_id));
                    GetGeoLicenceFunction("Login", result?.data?.data?.user_id, result?.data?.data?.token); 
                })  
                .catch(function (result) {
                    document.getElementById("pageisLoading").style.display = "none";
                    dispatch(getUser(""));
                    dispatch(checkLogin("no"));
                    dispatch(getAccessToken(""));
                    toast.error(result.response.data.error);
                    Navigate(HOME_URL);
                    if(result?.response?.status === 401) {
                        setTimeout(() => {
                            setFromBankendSignupDetails(result?.response?.data?.data);
                            signupFunction();
                        }, 500);
                    }
                });
        }).catch(function(error) {
            if (error.code === "auth/popup-closed-by-user") {
                document.getElementById("pageisLoading").style.display = "none";
            };
        });
    }

    // sign up or login with facebook
    const authWithfacebook = (e) => {
        e.preventDefault();
        dispatch(getRegioLcTime(""));
        dispatch(getOverCallingGeoLocation(true));
        document.getElementById("pageisLoading").style.display = "flex";
        window.axios.get(FACEBOOK_LOGIN_API_URL, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }}).then(function (result) {
                if(result?.status === 200) {
                  document.getElementById("pageisLoading").style.display = "none";
                  window.open(result?.data?.data,"_blank");
                };
            }).catch(function (result) {
                document.getElementById("pageisLoading").style.display = "none";
            });
    };

    useEffect(() => {
        if(Location?.search?.split("=")[1]?.split("#")[0] !== undefined) {
            const jsonData = JSON.stringify({ 
                uid: "AQAGlLj9fTaD1RJWzALy7c4KuILMNCYdZBeZkVtFGQ04x2cbxKdFVNSsox22kZouozTxg7aX4GdDMKG1QjzwbVKGrUlb85GBHG2DXbUGkajg_VjVnQReJh5MH4mJjy0Qd3lPl0fPJrpgqc4tXl7cXCFagiL4DVz8EA1Xk2pRT6xqOcH_VeXjfhcQSOclbVnHqTfvC3AjTk_1OMVQNpkH3F5tM3Wx88AQFCWtsUtx_WILyerEw4Dc0LGJOPHcXUkdCF_n6OMPIgyADDHBkNUt2VeO-z9oFbE53a-r_I_rDrXl8kJ0SlSVeZAQrmo1-eYmQBbAl1piSsq7167mzmGEkwXwEoBP26FOD-Bdud6Psq5iq7SKIblKdvOnZsYewOdjew80OcGo1TL2k5dhNjuOaD3DZFK27M8bRZ4cOV_IQOQFpQ", 
                type: "facebook"
            });
            window.axios.post(SOCIAL_FIREBASE_API_URL, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
                }).then(function (result) {
                    // dispatch(getUser(result?.data?.data?.user));
                    // dispatch(getAccessToken(result?.data?.data?.access_token));
                    // dispatch(checkLogin("yes"));
                    // toast.success(result.data.msg);
                    // Navigate(HOME_URL);
                    // setLoginSigupUp(false);
                    // ClearFields();
                    dispatch(getUniqueBrowserId(result?.data?.data?.user_id));
                    GetGeoLicenceFunction("Login", result?.data?.data?.user_id, result?.data?.data?.token); 
                })  
                .catch(function (result) {
                    document.getElementById("pageisLoading").style.display = "none";
                    dispatch(getUser(""));
                    dispatch(checkLogin("no"));
                    dispatch(getAccessToken(""));
                    toast.error(result.response.data.error);
                    Navigate(HOME_URL);
                    if(result?.response?.status === 401) {
                        setTimeout(() => {
                            setFromBankendSignupDetails(result?.response?.data?.data);
                            signupFunction();
                        }, 500);
                    }
                });
            
        };
    }, [ Location ]);


    return(<div className="loginsignupModals">
        <button className="closeModal" onClick={() => setLoginSigupUp(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><filter id="close_svg__a" width="136.7%" height="135.5%" x="-18.3%" y="-17.8%" filterUnits="objectBoundingBox"><feMorphology in="SourceAlpha" operator="dilate" radius="9" result="shadowSpreadOuter1"></feMorphology><feOffset dx="2" dy="12" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="14"></feGaussianBlur><feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix><feMerge><feMergeNode in="shadowMatrixOuter1"></feMergeNode><feMergeNode in="SourceGraphic"></feMergeNode></feMerge></filter></defs><g fillRule="evenodd" filter="url(#close_svg__a)" transform="translate(-421 -24)"><path d="m439.77 28 1.23 1.23-6.77 6.77 6.77 6.77-1.23 1.23-6.77-6.77-6.77 6.77-1.23-1.23 6.769-6.77L425 29.23l1.23-1.23 6.77 6.769L439.77 28z"></path></g></svg>
        </button>
        <div className="loginsignuptabs">
            <h1>{LoginSignupSet === "Login" ? "Log In" : "Forgot Password"}</h1>
            <div className="loginPopupMain">
                {LoginSignupSet === "Login" ? (<h4>New to this site? <span onClick={() => signupFunction()}>Sign Up</span></h4>) : (<h4>Please enter your email address</h4>)}
                {!loginWithForm && LoginSignupSet !== "Forgotpassword" ? (<React.Fragment>
                    <div className="fbgoglrbnts">
                        {process.env.REACT_APP_FACEBOOK_SHOW === "YES" && (<Button className="btn facebook" onClick={(e)=>authWithfacebook(e)}>
                            <span className="iconsn">
                                <img src={FaceBookIcon} alt="FaceBookIcon" />
                            </span>
                            Login with Facebook
                        </Button>)}
                        <Button className="btn google" onClick={(e)=>authWithgoogle(e)}>
                            <span className="iconsn">
                                <img src={GoogleIcon} alt="GoogleIcon" />
                            </span>
                            Login with Google
                        </Button>
                    </div>
                    <div className="ordevidedst">
                        <span>or</span>
                    </div>
                    <Button className="btn loginwithemail" onClick={() => setLoginWithForm(!loginWithForm)}>{LoginSignupSet === "Login" ? "Login" : "Sign up"} with Email</Button>
                </React.Fragment>) : (<div className="loginwitemilformwrps">
                    {LoginSignupSet === "Login" ? (<React.Fragment>
                        <div className={errorEmail !== "" ? "form-group error" : "form-group"}>
                            <label>Email</label>
                            <input type="email" className="d-none"  />
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} onKeyDown={(e) => onSubmitLogin(e)} autoComplete="off"/>
                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                        </div>
                        <div className={showPassword !== "" ? "form-group error" : "form-group"}>
                            <label>Password
                                <div className="passwordnotewrapper">
                                    <ol>
                                        {/* Minimum 8 characters. */}
                                        <li>The alphabet must be between [a-z]</li>
                                        <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                        <li>At least 1 number or digit between [0-9].</li>
                                        <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                    </ol>
                                    <Image src={InfoIcon} alt="info icon" />
                                </div>
                            </label>
                            <input type="password" className="d-none" />
                            <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => onSubmitLogin(e)} autoComplete="off"/>
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                            </span>
                            {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
                        </div>
                        <h5 className="forgotpassword" onClick={() => forgotpassFunction()}>Forgot password?</h5>
                        <Button className="btn loginbtn" onClick={() => loginFunction()} disabled={loaderStart}>
                            {loaderStart ? (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>) : "Log In"}
                        </Button>
                    </React.Fragment>) :
                    (<React.Fragment>
                        <div className={errorEmail !== "" ? "form-group error" : "form-group"}>
                            <label>Email</label>
                            <input type="email" className="d-none"  />
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off"/>
                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                        </div>
                        <Button className="btn loginbtn" onClick={() => ForgotPasswordFnct()}>Forgot Password</Button>
                    </React.Fragment>)}

                    {LoginSignupSet !== "Forgotpassword" && (<React.Fragment>
                        <div className="ordevidedst ordevidedstmrgin">
                            <span>or {LoginSignupSet === "Login" ? "log in" : "Sign Up"} with</span>
                        </div>
                        <div className="socillinks">
                            {process.env.REACT_APP_FACEBOOK_SHOW === "YES" && (<div>
                                <a href="javscript:void(0)" onClick={(e)=>authWithfacebook(e)}>
                                    <img src={FaceBookIcon} className="FaceBookIcon" alt="FaceBookIcon" />
                                </a>
                            </div>)}
                            <div>
                                <a href="javscript:void(0)" onClick={(e)=>authWithgoogle(e)}>
                                    <img src={GoogleIcon} alt="GoogleIcon" />
                                </a>
                            </div>
                        </div>
                    </React.Fragment>)}
                </div>)}
            </div>
        </div>
    </div>)
}

export default LoginSignupModal;