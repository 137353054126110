/* eslint-disable */
import React from "react";
import FooteImage from "../../../assets/img/sweepcoinscash-01.webp";
import {
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  PROMOTIONAL_RULES,
  CONTACT,
} from "../../Shared/constant";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer_section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footwrapper">
              <div className="col-md-4">
                <div className="contactlinks">
                  <h4>Contact</h4>
                  <ul>
                    <li>
                      <a href="mailto:support@sweepscoins.cash">
                        support@sweepscoins.cash
                      </a>
                    </li>
                    <li hidden>
                      Tel: <a href="tel:(888) 926-4679">(888) 9-COINS-9</a>
                    </li>
                    <li hidden>
                      Tel: <a href="tel:(888) 926-4679">(888) 926-4679</a>
                    </li>
                    <li className="d-flex flex-column justify-content-center align-items-center">
                      <div>625 Kenmoor Ave,</div>
                      <div> SE Suite 210 </div>
                      <div>Grand Rapids, MI. 49546</div>
                    </li>
                    <li hidden>
                      <a
                        href="https://www.sweepscoins.cash/_files/ugd/1e9d5f_fa38c2d661ad4fd6928a8a1f58e44b44.pdf"
                        target="_blank"
                        className="entrylink"
                      >
                        CLICK HERE FOR A FREE ENTRY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6 footprofilecol">
                <div className="contactlinks">
                  <h4>Policy Info</h4>
                  <ul>
                    <li>
                      <Link to={CONTACT}>Contact</Link>
                    </li>
                    <li>
                      <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Responsible Game Play</a>
                    </li>
                    <li>
                      <Link to={PROMOTIONAL_RULES}>Promotional Rules</Link>
                    </li>
                    <li>
                      <Link to={TERMS_CONDITIONS}>Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-6 col-xs-6 footsocialcol">
                            <div className="contactlinks">
                                <h4>Social</h4>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com" target="__blank">Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com" target="__blank">Youtube</a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com" target="__blank">Twitter</a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com" target="__blank">LinkedIn</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
              <div className="col-md-4">
                <div className="contadetails">
                  <h4>Enter Sweepstakes Here</h4>
                  <img src={FooteImage} alt="Enter Sweepstakes Here" />
                </div>
              </div>
            </div>
            <div className="copyright">
              &copy; {new Date().getFullYear()} Sweeps Coins Cash. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
