/* eslint-disable */
import React, { useEffect, useState } from "react";
import CustomMendotoryMsg from "../../Common/CustomMendotoryMsg";
import { Button, Image, Spinner, InputGroup } from "react-bootstrap";
import CountrySelectInput from "../../Common/CountrySelectInput/CountryMobileInput";
import SucessIcon from "../../../assets/img/success.svg";
import ErrorIcon from "../../../assets/img/error.svg";
import SweepsCoinsImage from "../../../assets/img/SWEEPSCOINS-Games.webp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "../../../assets/img/closeIcon.png"; 
import IDcard from "../../../assets/img/id-card.png";
import { getUser, getAccessToken, checkLogin, getUniqueBrowserId, getRegioLcTime, getOverCallingGeoLocation, IsSiteIsBlockCheck, getGeoCoplyMessage, getGeoCoplyLocation, getSpoofingDetection } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { SIGN_UP_API_URL, PROFILE_ID_UPLOAD, PHOTO_VERIFICATION_API_URL, USER_VALID_LOGIN_API_URL, TERMS_CONDITIONS, PROMOTIONAL_RULES, PRIVACY_POLICY } from "../constant";
import moment from "moment/moment";
import { Country, State, City }  from 'country-state-city';
import CameraIcon from "../../../assets/img/camera.png";
import BrowseImageIcon from "../../../assets/img/browse.png";
import CancelIcon from "../../../assets/img/cancel_icon.svg";
import CameraTakePhoto from "../../Common/CameraTakePhoto";
import Select from 'react-select';
import { Link } from "react-router-dom";
import InfoIcon from "../../../assets/img/information-button.png";

const SignUpSidebar = (props) => {
    const { SignUp, AllclearData, setAllclearData, setSignUp, setLoginSigupUp, fromBankendSignupDetails, setFromBankendSignupDetails, VerifiedLoginEmail, setVerifiedLoginEmail } = props;
    const dispatch = useDispatch();
    const [ fname, setFname ] = useState("");
    const [ lname, setLname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "1",
        number: "",
    });
    const [ CustomError, setCustomError ] = useState(false);
    const [ ProvideContent, setProvideContent ] = useState(false);
    const [ address, setAddress] = useState("");
    const [ bankname, setBankName] = useState("");
    const [ SSN, setSSN] = useState("");
    const [ SignupSetps, setSignupSetps ] = useState("personaldetails");
    const [ Birthdate, setBirthdate ] = useState("");
    const [ UploadedProfileId, setUploadedProfileId ] = useState("");
    const [ Paypal, setPaypal ] = useState("");
    const [ Cashapp, setCashapp ] = useState("");
    const [ Venmo, setVenmo ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\$%\^&\*])(?=.{8,})/;
    const SSNRegex = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
    const [ updateTermsCondtions, setUpdateTermsCondtions ] = useState(false);
    const [ FileUploadLoader, setFileUploadLoader ] = useState(false);
    const [ SelectedUploaderFile, setSelectedUploaderFile ] = useState("");
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);
    const [ SelectCountry, setSelectCountry ] = useState({
        key: 232,
        label: "United States",
        value: "US"
    });
    const [ SelectState, setSelectState ] = useState("");
    const [ SelectCity, setSelectCity ] = useState("");
    const [ zip, setZip ] = useState("");
    const [ imgSrc, setImgSrc ] = useState("");
    const [ FrontImage, setFrontImage ] = useState("");
    const [ BackImage, setBackImage ] = useState("");
    const [ selectIdType, setSelectIdType ] = useState("");
    const [ cameraOpen, setCameraOpen ] = useState(false);
    const [ kycVerificationLoader, setkycVerificationLoader ] = useState(false);
    const [ signupLog, setSignupLog ] = useState("");
    const [ UploadPhotoData, setUploadPhotoData ] = useState({
        front_image_id: "",
        back_image_id: "",
        face_image_id: ""
    });
    const [ PreviewImageTitle, setPreviewImageTitle ] = useState({
        front_image_title: "",
        back_image_title: "",
        face_image_title: ""
    });
    const [ checkUserIsBlock, setCheckUserIsBlock ] = useState({
        attempt: 0,
        isblock: "",
        directblock: false,
        permanent_attempt: 0
    });
    const [ RoutingNumber, setRoutingNumber ] = useState("");
    const [ AccountNumber, setAccountNumber ] = useState("");
    const [ BillingAddressOne, setBillingAddressOne ] = useState("");
    const [ BillingAddressTwo, setBillingAddressTwo ] = useState("");

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");
    const [ errorConfirmPassword, setErrorConfirmPassword ] = useState("");
    const [ MobileError, setMobileError ] = useState("");
    const [ SSNError, setSSNError ] = useState("");
    const [ BirthdateError, setBirthdateError ] = useState("");
    const [ errorCountry, setErrorCountry ] = useState("");
    const [ errorState, setErrorState ] = useState("");
    const [ errorCity, setErrorCity ] = useState("");
    const [ errorZip, setErrorZip ] = useState("");
    const [banknameerror,setBankNameError] = useState("")
    const [ errorImgSrc, setErrorImgSrc ] = useState("");
    const [ errorSelectIdType, setErrorSelectIdType ] = useState("");
    const [ errorFrontImage, setErrrFrontImage ] = useState("");
    const [ errorBackImage, setErrorBackImage ] = useState("");
    const [ errorTermsCondtions, setErrorTermsCondtions ] = useState("");
    const { geoComplyLocation, UniqueBrowserId } = useSelector((state) => state.allReducers);
    const [ emailCheckValid, setEmailCheckValid ] = useState(false);

    const [ Zipcodes, setZipcodes ] = useState([]);
    // camera capture image from pc or mobile
    // const uploadfiles = async (e) => {
    //     setImgSrc(await getBase64(e.target.files[0]).then((data) => data));
    // };

    // zipcodes function
    useEffect(() => {
        if(SignupSetps === "personaldetailssecond") {
            GetZipCodes();
        };
    }, [ SignupSetps ]);

    const GetZipCodes = async () => {
        const response = await fetch("https://sweepscoinscash.s3.us-east-2.amazonaws.com/json/zipcodes.json");
        const zipcodesdata = await response.json();
        setZipcodes(zipcodesdata);
    };

    useEffect(() => {
        if(VerifiedLoginEmail !== "" || VerifiedLoginEmail !== undefined) {
            setEmail(VerifiedLoginEmail);
        } else {
            setEmail("");
        }
    }, [ VerifiedLoginEmail ]);

    useEffect(() => {
        if(AllclearData === true) {
            ResetForm();
            setSignupSetps("personaldetails");
            document.getElementById("signupflag")?.classList?.remove("active");
            setTimeout(() => {
                setAllclearData(false);
            }, 200);
        };
    }, [ AllclearData ]);

    // upload image from pc or mobile
    const uploadfilesFrontImage = async (e) => {
        setFrontImage(await getBase64(e.target.files[0]).then((data) => data));
        setErrrFrontImage("");
    };

    // upload image from pc or mobile
    const uploadfilesBackImage = async (e) => {
        setBackImage(await getBase64(e.target.files[0]).then((data) => data));
        setErrorBackImage("");
    };
    
    // preview image 
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    };

    // check image state is empty or not
    useEffect(() => {
        if(imgSrc !== "") {
            setErrorImgSrc("");
        };
    }, [ imgSrc ]);

    // get country
    const options = Country.getAllCountries().map((guest, index) => {
        return {
           label: guest.name,
           value: guest.isoCode,
           key: index
        }
    });

    // get state
    const optionsState = State.getStatesOfCountry(SelectCountry.value).map((guest, index) => {
        return {
           label: guest.name,
           value: guest.isoCode,
           key: index
        }
    });

    // get city
    const optionsCity = City.getCitiesOfState(SelectCountry.value, SelectState.value).map((guest, index) => {
        return {
           label: guest.name,
           value: guest,
           key: index
        }
    });

   const customStyles = {
    control: base => ({
      ...base,
      height: 40,
      minHeight: 40,
      borderColor: "#e0e0e5"
    })
  };

    // select country 
    const SelectCountryChange = (data_) => {
        if(data_.value !== "") {
            setSelectCountry(data_);
            setErrorCountry("");
        } else {
            setSelectCountry("");
            setErrorCountry("Please select country");
        }
    };

    // select state 
    const SelectStateChange = (data_) => {
        if(data_.value !== "") {
            setSelectState(data_);
            setErrorState("");
            setSelectCity("");
            setZip("");
        } else {
            setSelectState("");
            setErrorState("Please select state");
        }
    };

    // select city 
    const SelectCityChange = (data_) => {
        if(data_.label !== "") {
            setSelectCity(data_);
            setErrorCity("");
            setZip("");
        } else {
            setSelectCity("");
            setErrorCity("Please select city");
        }
    };

    // select id type
    const SelectIdTypeOnchange = (e) => {
        if(e.target.value !== "") {
            setSelectIdType(e.target.value);
            setErrorSelectIdType("");
        } else {
            setSelectIdType("");
            setErrorSelectIdType("Please select Id Type");
        }
    };

    useEffect(() => {
        if(Object.keys(fromBankendSignupDetails).length > 0) {
            setFname(fromBankendSignupDetails.first_name);
            setLname(fromBankendSignupDetails?.last_name);
            setEmail(fromBankendSignupDetails?.email);
        } else {
            setFname(fromBankendSignupDetails.first_name === undefined ? "" : fromBankendSignupDetails.first_name);
            setLname(fromBankendSignupDetails.last_name === undefined ? "" : fromBankendSignupDetails?.last_name);
            setEmail(fromBankendSignupDetails.email === undefined ? "" : fromBankendSignupDetails?.email);
        };
    }, [ fromBankendSignupDetails ]);
    
    // signup function step first
    const SignUpStep = () => {
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && password !== "" && confirmPassword !== "") {
            if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true && PasswordRegex.test(confirmPassword) === true && password === confirmPassword) {
                setCustomError(false);
                setSignupSetps("personaldetailssecond");
                setErrorEmail("");
                setErrorPassword("");
                setErrorConfirmPassword("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(PasswordRegex.test(password) === false) {
                    setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character");
                } else {
                    setErrorPassword("");
                }
                if(PasswordRegex.test(confirmPassword) === false) {
                    setErrorConfirmPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character");
                } else if(password !== confirmPassword) {
                    setErrorConfirmPassword("Your password and confirm password do not match.");
                } else {
                    setErrorConfirmPassword("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if (password === "") {
                setErrorPassword("Password cannot be empty");
            } else if(PasswordRegex.test(password) === false) {
                setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.")
            } else {
                setErrorPassword("");
            }
            if (confirmPassword === "") {
                setErrorConfirmPassword("Confirm password cannot be empty");
            } else if(PasswordRegex.test(confirmPassword) === false) {
                setErrorConfirmPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.")
            } else {
                if(password !== confirmPassword) {
                    setErrorConfirmPassword("Your password and confirm password do not match.");
                } else {
                    setErrorConfirmPassword("");
                }
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    };

    // password validation
    const passWordGet = (value) => {
        setPassword(value);
        if (value === "") {
            setErrorPassword("Password cannot be empty");
        } else if(PasswordRegex.test(value) === false) {
            setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.")
        } else {
            setErrorPassword("");
        };
    };

    // confirm password validation
    const passWordGetConfirm = (value) => {
        setConfirmPassword(value);
        if (value === "") {
            setErrorConfirmPassword("Confirm password cannot be empty");
        } else if(PasswordRegex.test(value) === false) {
            setErrorConfirmPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.")
        } else {
            if(password !== value) {
                setErrorConfirmPassword("Your password and confirm password do not match.");
            } else {
                setErrorConfirmPassword("");
            }
        }
    };

    // login page open
    const GotoLogin = () => {
        setLoginSigupUp(true);
        setSignUp(false);
        setCustomError(false);
        setProvideContent(false);
    };    

    function subtractYearsFromDate(years) {
        var today = new Date();
        today.setFullYear(today.getFullYear() - years);
        return today;
    };

    // empty space validation fucntion
    const EmptySpaceFieldValid = (e) => {
        if (e.target.value.length === 0 && e.which === 32) {
        return e.preventDefault();
        };
    };

    // select terms and conditions
    const SelectTermsConditions = (e) => {
        if(e.target.checked) {
            setUpdateTermsCondtions(true);
            setErrorTermsCondtions("");
        } else {
            setUpdateTermsCondtions(false);
            setErrorTermsCondtions("Please accept terms & conditions");
        }
    };
      
      // Example usage:
    var newDate = subtractYearsFromDate(22);

    const ZipCodesFilter = Zipcodes.filter((elm) => elm?.state.toString() === SelectState.value);
    const ZipCodeValids = ZipCodesFilter.filter((elm) => elm?.zip_code.toString() === zip).length > 0;
    // console.log("ZipCodesFilter",ZipCodesFilter)

    // valid ssn
    const ValidSSN = (value) => {
        if(value === "") {
            setSSNError("SSN cannot be empty");
        } else if(SSNRegex.test(value) === false) {
            setSSNError("Please enter valid SSN");
        } else {
            setSSNError("");
        }
    };

    // valid zip
    const ValidZIP = (value) => {
        if(value === "") {
            setErrorZip("Zip cannot be empty");
        } else if(ZipCodeValids === false) {
            setErrorZip("Please enter valid zip")
        } else {
            setErrorZip("");
        }
    };

    useEffect(() => {
        if(zip !== "") {
            if(zip === "") {
                setErrorZip("Zip cannot be empty");
            } else if(ZipCodeValids === false) {
                setErrorZip("Please enter valid zip")
            } else {
                setErrorZip("");
            }
        }
    }, [ ZipCodeValids ]);

    const ValidSSNState = (e) => {
        setSSN(cc_format(e.target.value.trim()))
    };

    const cc_format = (value) => {
         // remove all non-dash and non-numerals
        var val = value.replace(/[^\d-]/g, '');

        // add the first dash if number from the second group appear
        val = val.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2');

        // add the second dash if numbers from the third group appear
        val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

        // remove misplaced dashes
        val = val.split('').filter((val, idx) => {
            return val !== '-' || idx === 3 || idx === 6;
        }).join('');

        // enforce max length
        return val.substring(0, 11);
    };

    const signupsubmit = () => {
        document.getElementById("signupflag")?.classList?.remove("active");
        if(Birthdate !== "" && SSN !== "" && address !== "" && SelectCountry !== "" && SelectState !== "" && SelectCity !== "" && zip !== "" && updateTermsCondtions !== false) { // <-- && UploadedProfileId !== ""
            if(SSNRegex.test(SSN) === true && ZipCodeValids === true) {
                dispatch(getRegioLcTime(""));
                dispatch(getOverCallingGeoLocation(true));
                setkycVerificationLoader(true);
                setCustomError(false);

                let jsonData;
                if(emailCheckValid) {
                    jsonData = { 
                        "first_name": fname, 
                        "last_name": lname, 
                        "email": email.toLowerCase(), 
                        "password": confirmPassword, 
                        "phone": MobileNo?.countrycode.toString() + "-" + MobileNo?.number.toString(),
                        "ssn": SSN?.replaceAll("-", ""),
                        "dob_year": moment(Birthdate).format('YYYY'),
                        "dob_month": moment(Birthdate).format('MM'),
                        "dob_day": moment(Birthdate).format('DD'),
                        "street_address": address,
                        // "payment_method": Paypal !== "" ? Paypal: Cashapp !== "" ? Cashapp : Venmo,
                        "country": SelectCountry.value,
                        "state": SelectState.value,
                        "city": SelectCity.label === "New York City" ? "New York" : SelectCity.label,
                        "zip": zip,
                        "is_email_check": emailCheckValid,
                        "billing_address1": BillingAddressOne,
                        "billing_address2": BillingAddressTwo
                    };
                } else {
                    jsonData = { 
                        "first_name": fname, 
                        "last_name": lname, 
                        "email": email.toLowerCase(), 
                        "password": confirmPassword, 
                        "phone": MobileNo?.countrycode.toString() + "-" + MobileNo?.number.toString(),
                        "ssn": SSN?.replaceAll("-", ""),
                        "dob_year": moment(Birthdate).format('YYYY'),
                        "dob_month": moment(Birthdate).format('MM'),
                        "dob_day": moment(Birthdate).format('DD'),
                        "street_address": address,
                        // "payment_method": Paypal !== "" ? Paypal: Cashapp !== "" ? Cashapp : Venmo,
                        "country": SelectCountry.value,
                        "state": SelectState.value,
                        "city": SelectCity.label === "New York City" ? "New York" : SelectCity.label,
                        "zip": zip,
                        "bank_name":bankname,
                        "routing_number": RoutingNumber,
                        "account_number": AccountNumber,
                        "is_email_check": emailCheckValid,
                        "billing_address1": BillingAddressOne,
                        "billing_address2": BillingAddressTwo
                    };
                }
                const paramfilter = Object.entries(jsonData).filter((elm) => {
                    if(elm[1] !== "") {
                        return elm;
                    }
                });
                // console.log("paramfilter",jsonData)



                window.axios.post(SIGN_UP_API_URL, JSON.stringify(Object.fromEntries(paramfilter)), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(function (result) {
                    // console.log("result", result?.response)
                    dispatch(getUniqueBrowserId(result?.data?.data?.user_id));
                    GetGeoLicenceFunction("signup", result?.data?.data?.user_id, result?.data?.data?.token); 
                }).catch(function (result) {
                    if(result?.response?.data?.error?.attempt) {
                        setCheckUserIsBlock({
                            ...checkUserIsBlock,
                            attempt: result?.response?.data?.error?.attempt === "" ? 0 : result?.response?.data?.error?.attempt,
                            isblock: result?.response?.data?.error?.match,
                            directblock: result?.response?.data?.error?.direct_block === undefined ? false : result?.response?.data?.error?.direct_block,
                            permanent_attempt: result?.response?.data?.error?.permanent_attempt === "" ? 0 : result?.response?.data?.error?.permanent_attempt
                        });

                        setTimeout(() => {
                            setCheckUserIsBlock({
                                ...checkUserIsBlock,
                                attempt: 0,
                                isblock: "",
                                directblock: false,
                                permanent_attempt: 0
                            });
                        }, 5000);
                    } else if(result?.response?.data?.error?.match === "block") {
                        setCheckUserIsBlock({
                            ...checkUserIsBlock,
                            attempt: result?.response?.data?.error?.attempt === "" ? 0 : result?.response?.data?.error?.attempt,
                            isblock: result?.response?.data?.error?.match,
                            directblock: result?.response?.data?.error?.direct_block === undefined ? false : result?.response?.data?.error?.direct_block,
                            permanent_attempt: result?.response?.data?.error?.permanent_attempt === "" ? 0 : result?.response?.data?.error?.permanent_attempt
                        });

                        if(checkUserIsBlock?.permanent_attempt === 0) {
                            setTimeout(() => {
                                // setSignupSetps("personaldetails");
                                setCheckUserIsBlock({
                                    ...checkUserIsBlock,
                                    attempt: 0,
                                    isblock: "",
                                    directblock: false,
                                    permanent_attempt: 0
                                });
                            }, 5000);
                        };
                        if(checkUserIsBlock?.permanent_attempt === 3) {
                            setTimeout(() => {
                                setCheckUserIsBlock({
                                    ...checkUserIsBlock,
                                    attempt: 0,
                                    isblock: "",
                                    directblock: false,
                                    permanent_attempt: 0
                                });
                                ResetForm();
                            }, 5000);
                        };
                    } else if(result?.response?.data?.error?.match === "permanent-block") {
                        setCheckUserIsBlock({
                            ...checkUserIsBlock,
                            attempt: result?.response?.data?.error?.attempt === "" ? 0 : result?.response?.data?.error?.attempt,
                            isblock: result?.response?.data?.error?.match,
                            directblock: result?.response?.data?.error?.direct_block === undefined ? false : result?.response?.data?.error?.direct_block,
                            permanent_attempt: result?.response?.data?.error?.permanent_attempt === "" ? 0 : result?.response?.data?.error?.permanent_attempt
                        });

                        // if(checkUserIsBlock?.permanent_attempt === 3) {
                            setTimeout(() => {
                                setSignupSetps("personaldetails");
                                setCheckUserIsBlock({
                                    ...checkUserIsBlock,
                                    attempt: 0,
                                    isblock: "",
                                    directblock: false,
                                    permanent_attempt: 0
                                });
                                ResetForm();
                            }, 5000);
                        // };
                    };
                    
                    setkycVerificationLoader(false);
                    if(result?.response?.status === 401) {
                        dispatch(getUser(""));
                        dispatch(checkLogin("no"));
                        dispatch(getAccessToken(""));
                        // setSignupSetps("signupPhotoIdVerification");
                        // setSelectCountry(Country.getCountryByCode(SelectCountry));
                        PageScrollBottom();
                        if(Array.isArray(result?.response?.data?.error?.error)) {
                            toast.error(<ul>{result?.response?.data?.error?.error.map((msg, index) => {
                                return(<li key={index}>{msg}</li>)
                            })}</ul>);
                        } else {
                            toast.error(result?.response?.data?.error?.error, { autoClose: 5000 });
                        }
                    } else {
                        dispatch(getUser(""));
                        dispatch(checkLogin("no"));
                        dispatch(getAccessToken(""));
                        toast.error(result?.response?.data?.error, { autoClose: 5000 });
                    }
                    // if(result?.response?.data?.error === "There was an issue verifying your identity, please contact customer support or try after 4 hour.") {
                    //     setSignupSetps("personaldetails");
                    //     ResetForm();
                    // };
                });
            } else {
                if(SSN === "") {
                    setSSNError("SSN cannot be empty");
                } else if(SSNRegex.test(SSN) === false) {
                    setSSNError("Please enter valid SSN");
                } else {
                    setSSNError("");
                }
                if(zip === "") {
                    setErrorZip("Zip code cannot be empty")
                } else if(ZipCodeValids === false) {
                    setErrorZip("Please enter valid zip")
                } else {
                    setErrorZip("")
                }
            }
        } else {
            setCustomError(true);
            if(SSN === "") {
                setSSNError("SSN cannot be empty");
            } else if(SSNRegex.test(SSN) === false) {
                setSSNError("Please enter valid SSN");
            } else {
                setSSNError("");
            }
            if(SelectCountry === "") {
                setErrorCountry("Please select country");
            } else {
                setErrorCountry("");
            }
            if(SelectState === "") {
                setErrorState("Please select state");
            } else {
                setErrorState("");
            }
            if(SelectCity === "") {
                setErrorCity("Please select city");
            } else {
                setErrorCity("");
            }
            if(zip === "") {
                setErrorZip("Zip code cannot be empty")
            } else if(ZipCodeValids === false) {
                setErrorZip("Please enter valid zip")
            } else {
                setErrorZip("")
            }
            if(updateTermsCondtions === false) {
                setErrorTermsCondtions("Please accept terms & conditions");
            } else {
                setErrorTermsCondtions("");
            }
        };    
    }

    const GetGeoLicenceFunction = async (url, id, token) => {
        document.getElementById("pageisLoading").style.display = "flex";
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            document.getElementById("signupflag")?.classList?.remove("active");
            if(result?.status === 200) {
                GetGeoLocate(result?.data?.data?.license, url, id, token);
                dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            document.getElementById("signupflag")?.classList?.remove("active");
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url, id, token) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(id);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text, id, token);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // callback call
    const callGeoTimeApi = async (re_geolocation) => {
        if(re_geolocation !== null) {
            var reGeoLcTime = re_geolocation * 1000;
            localStorage.setItem("reGeoLcTime", reGeoLcTime.toString());
            var currentTimeMillis = Date.now();
            var currentTimeString = currentTimeMillis.toString();
            localStorage.setItem("reGeoLcTimeStamp", currentTimeString);
            // setTimeout(() => {
            //     GetGeoLicenceFunction("Periodic");
            // }, reGeoLcTime);
        }
    };
    
    // location Permissions api
    const LocationPermission = async (text, id, token) => {
        console.log("Signup Permission");
        const jsonData = JSON.stringify({ 
        "id": id,
        "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            if(result?.status === 200) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
                if(result?.data?.data?.status === true) {
                    dispatch(getOverCallingGeoLocation(false));
                    SignupFunctionGeo(token, text);
                    // success code
                    dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
                    localStorage.removeItem("starttime");
                    localStorage.removeItem("targettime");
                }
                dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
                if(result?.data?.data?.status === false) {
                    document.getElementById("pageisLoading").style.display = "none";
                    dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));

                    if(result?.data?.data?.spoofing_detection != null){
                        // add spoofing detection popup
                        console.log("spoofing detection");
                    }
                }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
            
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            // localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };
    
    // signup submit function
    const SignupFunctionGeo = (token, text) => {
        const jsonData = JSON.stringify({ 
            "token" : token, 
            "location" : text 
        });
        window.axios.post(USER_VALID_LOGIN_API_URL, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }}).then(function (result) {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(getUser(result?.data?.data?.user));
                dispatch(getAccessToken(result?.data?.data?.access_token));
                localStorage.setItem("access_tokens", result?.data?.data?.access_token);
                dispatch(checkLogin("yes"));
                toast.success(result.data.msg);
                closeSidebar();
                setSignupSetps("personaldetails");
                setkycVerificationLoader(false);
                ResetForm();
            }).catch(function (result) {
                toast.error(result.response.data.error);
                document.getElementById("pageisLoading").style.display = "none";
                setloaderStart(false);
                Navigate(HOME_URL);
                if(result?.response?.data?.error?.attempt) {
                    setCheckUserIsBlock({
                        ...checkUserIsBlock,
                        attempt: result?.response?.data?.error?.attempt === "" ? 0 : result?.response?.data?.error?.attempt,
                        isblock: result?.response?.data?.error?.match,
                        directblock: result?.response?.data?.error?.direct_block === undefined ? false : result?.response?.data?.error?.direct_block,
                        permanent_attempt: result?.response?.data?.error?.permanent_attempt === "" ? 0 : result?.response?.data?.error?.permanent_attempt
                    });

                    if(result?.response?.data?.error?.permanent_attempt === 0) {
                        setTimeout(() => {
                            // setSignupSetps("personaldetails");
                            setCheckUserIsBlock({
                                ...checkUserIsBlock,
                                attempt: 0,
                                isblock: "",
                                directblock: false,
                                permanent_attempt: 0
                            });
                        }, 5000);
                    };
                    if(result?.response?.data?.error?.permanent_attempt === 3) {
                        setTimeout(() => {
                            setSignupSetps("personaldetails");
                            setCheckUserIsBlock({
                                ...checkUserIsBlock,
                                attempt: 0,
                                isblock: "",
                                directblock: false,
                                permanent_attempt: 0
                            });
                            ResetForm();
                        }, 5000);
                    };
                } else if(result?.response?.data?.error?.match === "block") {
                    setCheckUserIsBlock({
                        ...checkUserIsBlock,
                        attempt: result?.response?.data?.error?.attempt === "" ? 0 : result?.response?.data?.error?.attempt,
                        isblock: result?.response?.data?.error?.match,
                        directblock: result?.response?.data?.error?.direct_block === undefined ? false : result?.response?.data?.error?.direct_block,
                        permanent_attempt: result?.response?.data?.error?.permanent_attempt === "" ? 0 : result?.response?.data?.error?.permanent_attempt
                    });
                    if(result?.response?.data?.error?.permanent_attempt === 0) {
                        setTimeout(() => {
                            // setSignupSetps("personaldetails");
                            setCheckUserIsBlock({
                                ...checkUserIsBlock,
                                attempt: 0,
                                isblock: "",
                                directblock: false,
                                permanent_attempt: 0
                            });
                        }, 5000);
                    };
                    if(result?.response?.data?.error?.permanent_attempt === 3) {
                        setTimeout(() => {
                            setSignupSetps("personaldetails");
                            setCheckUserIsBlock({
                                ...checkUserIsBlock,
                                attempt: 0,
                                isblock: "",
                                directblock: false,
                                permanent_attempt: 0
                            });
                            ResetForm();
                        }, 5000);
                    };
                } else if(result?.response?.data?.error?.match === "permanent-block") {
                    setCheckUserIsBlock({
                        ...checkUserIsBlock,
                        attempt: result?.response?.data?.error?.attempt === "" ? 0 : result?.response?.data?.error?.attempt,
                        isblock: result?.response?.data?.error?.match,
                        directblock: result?.response?.data?.error?.direct_block === undefined ? false : result?.response?.data?.error?.direct_block,
                        permanent_attempt: result?.response?.data?.error?.permanent_attempt === "" ? 0 : result?.response?.data?.error?.permanent_attempt
                    });

                    if(result?.response?.data?.error?.permanent_attempt === 3) {
                        setTimeout(() => {
                            setSignupSetps("personaldetails");
                            setCheckUserIsBlock({
                                ...checkUserIsBlock,
                                attempt: 0,
                                isblock: "",
                                directblock: false,
                                permanent_attempt: 0
                            });
                            ResetForm();
                        }, 5000);
                    };
                };;
                
                setkycVerificationLoader(false);
                if(result?.response?.status === 401) {
                    dispatch(getUser(""));
                    dispatch(checkLogin("no"));
                    dispatch(getAccessToken(""));
                    // setSignupSetps("signupPhotoIdVerification");
                    // setSelectCountry(Country.getCountryByCode(SelectCountry));
                    PageScrollBottom();
                    if(Array.isArray(result?.response?.data?.error?.error)) {
                        toast.error(<ul>{result?.response?.data?.error?.error.map((msg, index) => {
                            return(<li key={index}>{msg}</li>)
                        })}</ul>);
                    } else {
                        toast.error(result?.response?.data?.error?.error, { autoClose: 5000 });
                    }
                } else {
                    dispatch(getUser(""));
                    dispatch(checkLogin("no"));
                    dispatch(getAccessToken(""));
                    toast.error(result?.response?.data?.error, { autoClose: 5000 });
                }
                // if(result?.response?.data?.error === "There was an issue verifying your identity, please contact customer support or try after 4 hour.") {
                //     setSignupSetps("personaldetails");
                //     ResetForm();
                // };
                
            });
    };


    let PageScroll = document.getElementById("MainWrapsId");
    useEffect(() => {
        if(SignupSetps === "signupPhotoIdVerification") {
            PageScrollBottom();
        }
    }, [ SignupSetps ])

    const PageScrollBottom = () => {
        PageScroll.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    // upload file function
    const UploadFile = (e, type, url) => {
        if(selectIdType !== "") {
            if(url === "front_image") {
                setFileUploadLoader(true);
                const files = e.target.files[0];
                setPreviewImageTitle({...PreviewImageTitle, front_image_title: e.target.files[0]?.name})
                const formData = new FormData();
                formData.append('email', email);
                formData.append('id_type', selectIdType);
                formData.append('photo_type', type);
                formData.append('file', files);
        
                window.axios.post( PROFILE_ID_UPLOAD, formData, ).then(function (result) {
                    if (result.data) {
                        setUploadPhotoData({...UploadPhotoData, front_image_id: result?.data?.id});
                        toast.success(result.data.msg);
                        setTimeout(() => setFileUploadLoader(false), 100);
                    }
                }).catch(function (result) {
                    toast.error(result.response.data.error);
                    setTimeout(() => setFileUploadLoader(false), 100);
                });
            } else if(url === "back_image") {
                setFileUploadLoader(true);
                const files = e.target.files[0];
                setPreviewImageTitle({...PreviewImageTitle, back_image_title: e.target.files[0]?.name})
                const formData = new FormData();
                formData.append('email', email);
                formData.append('id_type', selectIdType);
                formData.append('photo_type', type);
                formData.append('file', files);
        
                window.axios.post( PROFILE_ID_UPLOAD, formData, ).then(function (result) {
                    if (result.data) {
                        setUploadPhotoData({...UploadPhotoData, back_image_id: result?.data?.id});
                        toast.success(result.data.msg);
                        setTimeout(() => setFileUploadLoader(false), 100);
                    }
                }).catch(function (result) {
                    toast.error(result.response.data.error);
                    setTimeout(() => setFileUploadLoader(false), 100);
                });
            } else {
                setFileUploadLoader(true);
                const files = e;
                setPreviewImageTitle({...PreviewImageTitle, face_image_title: "face Image"})
                const formData = new FormData();
                formData.append('email', email);
                formData.append('id_type', selectIdType);
                formData.append('photo_type', type);
                formData.append('file', files);
        
                window.axios.post( PROFILE_ID_UPLOAD, formData, ).then(function (result) {
                    if (result.data) {
                        setUploadPhotoData({...UploadPhotoData, face_image_id: result?.data?.id});
                        toast.success(result.data.msg);
                        setTimeout(() => setFileUploadLoader(false), 100);
                    }
                }).catch(function (result) {
                    toast.error(result.response.data.error);
                    setTimeout(() => setFileUploadLoader(false), 100);
                });
            }
        } else {
            alert("Please enter id type")
        }
    };

    // file remove
    const SelectFileRemove = (url) => {
        if(url === "face_image") {
            setPreviewImageTitle({...PreviewImageTitle, face_image_title: ""});
            setUploadPhotoData({...UploadPhotoData, face_image_id: ""});
        } else if(url === "front_image") {
            setPreviewImageTitle({...PreviewImageTitle, front_image_title: ""});
            setUploadPhotoData({...UploadPhotoData, front_image_id: ""});
        } else {
            setPreviewImageTitle({...PreviewImageTitle, back_image_title: ""});
            setUploadPhotoData({...UploadPhotoData, back_image_id: ""});
        }
    };

    // photo id verifications
    const photoIDVerification = () => {
        if(email !== "" && UploadPhotoData?.face_image_id !== "" && UploadPhotoData?.front_image_id !== "" && UploadPhotoData?.back_image_id !== "" && Country !== "" && selectIdType !== "") {
            const jsonData = JSON.stringify({ 
                email: email.toLowerCase(), 
                photo_ids: Object.values(UploadPhotoData).toString(),
                ssn: SSN,
                country: SelectCountry,
                log: ""
            });
            window.axios.post(PHOTO_VERIFICATION_API_URL, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(function (result) {
                setTimeout(() => {
                    dispatch(getUser(result?.data?.data?.user));
                    dispatch(getAccessToken(result?.data?.data?.access_token));
                    dispatch(checkLogin("yes"));
                    toast.success(result.data.msg);
                    closeSidebar();
                    setSignupSetps("personaldetails");
                    setkycVerificationLoader(false);
                    ResetForm();
                }, 200);
            }).catch(function (result) {
                dispatch(getUser(""));
                dispatch(checkLogin("no"));
                dispatch(getAccessToken(""));
                toast.error(result?.response?.data?.error);
            });
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(imgSrc === "") {
                setErrorImgSrc("Face Image cannot be empty");
            } else {
                setErrorImgSrc("")
            }
            if(UploadPhotoData?.back_image_id === "") {
                setErrrFrontImage("Back Image cannot be empty");
            } else {
                setErrrFrontImage("");
            }
            if(UploadPhotoData?.front_image_id === "") {
                setErrorBackImage("Front Image cannot be empty");
            } else {
                setErrorBackImage("");
            }
            if(SelectCountry === "") {
                setErrorCountry("Please select country");
            } else {
                setErrorCountry("");
            }
            if(selectIdType === "") {
                setErrorSelectIdType("Please select Id Type");
            } else {
                setErrorSelectIdType("");
            }
        };
    };

    // clear for data
    const ResetForm = () => {
        setFname("");
        setLname("");
        setEmail("");
        setPassword("");
        setMobileNo({...MobileNo, number: ""})
        setAddress("");
        setBankName("");
        setSSN("");
        setBirthdate("");
        setUploadedProfileId("");
        setPaypal("");
        setCashapp("");
        setShowPassword(false);
        setShowConfirmPassword(false);
        setVenmo("");
        setPassword("")
        setConfirmPassword("")
        setErrorConfirmPassword("");
        setErrorPassword("");
        setMobileError("");
        SelectFileRemove();
        setFromBankendSignupDetails({});
        setSelectCity("");
        setSelectState("");
        setZip("");
        setVerifiedLoginEmail("");
        setUpdateTermsCondtions(false);
        setRoutingNumber("");
        setAccountNumber("");
        setBillingAddressOne("");
        setBillingAddressTwo("");
        setEmailCheckValid(false);
    };
    //copy billine one address
    const copyBilling1=()=>{
        setBillingAddressTwo(BillingAddressOne)
     } 
     const handleNumericInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };
    // close sidebar function
    const closeSidebar = (url) => {
        if(url !== undefined) {
            setTimeout(() => {
                setSignUp(false);
                document.getElementById("signupflag")?.classList?.remove("active");
            }, 100)
        } else {
            setTimeout(() => {
                setFromBankendSignupDetails({});
                setSSNError("");
                setSignupSetps("personaldetails");
                setSignUp(false);
                setCustomError(false);
                setProvideContent(false);
                setErrorEmail("");
                setErrorPassword("");
                ResetForm();
                setMobileError("");
                setErrorCountry("");
                setErrorState("");
                setErrorCity("");
                setVerifiedLoginEmail("");
                document.getElementById("signupflag")?.classList?.remove("active");
            }, 100)
        }
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxZip")) {
            document.activeElement.blur();
        }
    });

    return(<React.Fragment>
        <div className={SignUp === true ? "sidebarcommon open" : "sidebarcommon"} >
            <div id="MainWrapsId" style={{ paddingTop: "40px" }}>
            <h2>SIGN UP
                <svg id="closesignsidebar" onClick={() => closeSidebar()} preserveAspectRatio="xMidYMid meet" data-bbox="82.189 55.096 481.811 481.808" xmlns="http://www.w3.org/2000/svg" viewBox="82.189 55.096 481.811 481.808" role="presentation" aria-hidden="true">
                    <g>
                        <path d="M531.936 536.904L323.094 328.063 114.253 536.904l-32.064-32.062L291.032 296 82.189 87.157l32.064-32.061 208.842 208.842L531.936 55.096 564 87.157 355.155 296 564 504.842l-32.064 32.062z"></path>
                    </g>
                </svg>
            </h2>
            {SignupSetps === "signupPhotoIdVerification" ? (<>
                <p>Photo ID Verification!</p>
            </>) : (<>
                <p>Join Sweepcoins.cash and Redeem Your Winnings!</p>
                <p>Already a member? <span className="loginlink" onClick={() => GotoLogin()}>Click Here to Login</span></p>
            </>)}
            {SignupSetps === "personaldetails" ? (<div className="paymentformsWraps signupfields">
                <div className="rowcustom rowcustom-col-2">
                    <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                        <label>First Name *</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" autoComplete="off"/>
                            {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                        </div>
                    </div>
                    <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                        <label>Last Name *</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" autoComplete="off"/>
                            {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className={(errorEmail !== "" && EmailRegex.test(email) === false) ? "form-group error" : "form-group"}>
                        <label>Email *</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} maxLength="100" placeholder="Enter Email" autoComplete="off"/>
                            {EmailRegex.test(email) === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {EmailRegex.test(email) === false && <div className="danger-color">{errorEmail}</div>}
                        </div>
                    </div>
                    <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                        <label>Phone *</label>
                        <div className="form-groupfiled">
                            <CountrySelectInput 
                                MobileNo={MobileNo}
                                setMobileNo={setMobileNo}
                                id={"signupflag"}
                            />
                            {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2 paswordfield">
                    <div className={(errorPassword !== "" && PasswordRegex.test(password) !== true) ? "form-group error" : "form-group"}>
                        <label>Password *
                            <div className="passwordnotewrapper">
                                <ol>
                                    {/* Minimum 8 characters. */}
                                    <li>The alphabet must be between [a-z]</li>
                                    <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                    <li>At least 1 number or digit between [0-9].</li>
                                    <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                </ol>
                                <Image src={InfoIcon} alt="info icon" />
                            </div>
                        </label>
                        <div className="form-groupfiled passwordboxset">
                            <input type="password" className="d-none" style={{ display: "none" }} />
                            <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => passWordGet(e.target.value)} placeholder="Enter Password" autoComplete="new-password" />
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                            </span>
                            {(errorPassword !== "" && PasswordRegex.test(password) !== true) && <div className="danger-colorset">{errorPassword}</div>}
                        </div>
                    </div>
                    <div className={(errorConfirmPassword !== "" && PasswordRegex.test(confirmPassword) !== true) ? "form-group error" : "form-group"}>
                        <label>Confirm Password *
                            <div className="passwordnotewrapper">
                                <ol>
                                    {/* Minimum 8 characters. */}
                                    <li>The alphabet must be between [a-z]</li>
                                    <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                    <li>At least 1 number or digit between [0-9].</li>
                                    <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                </ol>
                                <Image src={InfoIcon} alt="info icon" />
                            </div>
                        </label>
                        <div className="form-groupfiled passwordboxset">
                            <input type="password" className="d-none"  style={{ display: "none" }} />
                            <input type={showConfirmPassword ? "text" : "password"} className="form-control" value={confirmPassword} onChange={(e) => passWordGetConfirm(e.target.value)} placeholder="Enter Confirm Password" autoComplete="new-password" />
                            <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                            </span>
                            {errorConfirmPassword !== "" && <div className="danger-colorset">{errorConfirmPassword}</div>}
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className="form-group">
                        <Button className="btn formcomnbtn" onClick={() => SignUpStep()}>Next</Button>
                    </div>
                </div>
            </div>) : SignupSetps === "personaldetailssecond" ? (<div className="paymentformsWraps signupfields">
                {checkUserIsBlock?.isblock === "fail" && (<>
                    {checkUserIsBlock?.attempt !== 0 && (<div className="erroBlockSet">
                    {checkUserIsBlock?.attempt === 1 ? "Attention: Only 1 Verification Attempt Left!" : "Maximum failed registration attempts Please try again after 4 hours."}
                </div>)}
                </>)} 
                {checkUserIsBlock?.isblock === "partial" && (<>
                    {checkUserIsBlock?.attempt !== 0 && (<div className="erroBlockSet">
                    {checkUserIsBlock?.attempt === 1 ? "Attention: Only 2 Verification Attempts Left!" : checkUserIsBlock?.attempt == 2 ? "Attention: Only 1 Verification Attempt Left!" : "Maximum failed registration attempts Please try again after 4 hours."}
                </div>)}
                </>)}
                
                {(checkUserIsBlock?.isblock === "block" && checkUserIsBlock?.directblock === true) && (<>{checkUserIsBlock?.permanent_attempt === 0 ? (<div className="erroBlockSet">
                    Maximum failed registration attempt. Please retry after 4 hours.
                </div>) : (<div className="erroBlockSet">
                    {checkUserIsBlock?.permanent_attempt === 1 ? "Attention: You've reached the maximum partial attempts. Please retry after 4 hours, and please be aware that you have only 2 attempts left before your account is permanently blocked." : checkUserIsBlock?.permanent_attempt == 2 ? "You've reached the maximum partial attempts. Please retry after 4 hours, and please be aware that you have only 1 attempts left before your account is permanently blocked." : "Attention: You've reached the maximum number of attempts, and your account is now permanently blocked."}
                </div>)}</>)}
                {(checkUserIsBlock?.isblock === "block" && checkUserIsBlock?.directblock === false) && (<>{checkUserIsBlock?.permanent_attempt === 0 ? (<div className="erroBlockSet">
                    Maximum failed registration attempts Please try again after 4 hours.
                </div>) : (<div className="erroBlockSet">
                    {checkUserIsBlock?.permanent_attempt === 1 ? "Attention: You've reached the maximum partial attempts. Please retry after 4 hours, and please be aware that you have only 2 attempts left before your account is permanently blocked." : checkUserIsBlock?.permanent_attempt == 2 ? "You've reached the maximum partial attempts. Please retry after 4 hours, and please be aware that you have only 1 attempts left before your account is permanently blocked." : "Attention: You've reached the maximum number of attempts, and your account is now permanently blocked."}
                </div>)}</>)}
                {checkUserIsBlock?.isblock === "permanent-block" && (<div className="erroBlockSet">Attention: You've reached the maximum number of attempts, and your account is now permanently blocked.</div>)}

                <div className="rowcustom rowcustom-col-2">
                    <div className="form-group">
                        <label>Country *</label>
                        <div className="form-groupfiled">
                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={SelectCountry}
                                isClearable={true}
                                name="isoCode"
                                label={"isoCode"} 
                                options={options} 
                                value={SelectCountry}
                                onChange={SelectCountryChange}
                                styles={customStyles}
                            />
                            {errorCountry !== "" && <div className="danger-color">{errorCountry}</div>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>State *</label>
                        <div className="form-groupfiled">
                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={SelectState}
                                isClearable={true}
                                name="isoCode"
                                label={"isoCode"} 
                                options={optionsState} 
                                value={SelectState}
                                onChange={SelectStateChange}
                                styles={customStyles}
                            />
                            {errorState !== "" && <div className="danger-color">{errorState}</div>}
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className="form-group">
                        <label>City *</label>
                        <div className="form-groupfiled">
                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={SelectCity}
                                isClearable={true}
                                name="isoCode"
                                label={"isoCode"} 
                                options={optionsCity} 
                                value={SelectCity}
                                onChange={SelectCityChange}
                                styles={customStyles}
                            />
                            {errorCity !== "" && <div className="danger-color">{errorCity}</div>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Zip Code *</label>
                        <div className="form-groupfiled">
                            <input type="number" className="form-control inputBoxZip" value={zip} onChange={(e) => setZip(e.target.value)} onKeyUp={(e) => ValidZIP(e.target.value)}  placeholder="Enter Zip Code"/>
                            {(zip !== "" && errorZip === "") && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {errorZip !== "" && <img src={ErrorIcon} className="errorsuccessicon" alt={"icon"} />}
                            {errorZip !== "" && <div className="danger-color">{errorZip}</div>}
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className={CustomError && SSN === "" ? "form-group error" : "form-group"}>
                        <label>SSN *</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={cc_format(SSN)} onChange={(e) => ValidSSNState(e)} onKeyUp={(e) => ValidSSN(e.target.value)} maxLength={11} placeholder="Enter SSN" />
                            {/* <input type="text" className="form-control" value={SSN} onChange={(e) => setSSN(e.target.value.trim())} onKeyUp={(e) => ValidSSN(e.target.value)} maxLength={20} placeholder="Enter SSN" /> */}
                            {(SSN !== "" && SSNError === "") && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {SSNError !== "" && <img src={ErrorIcon} className="errorsuccessicon" alt={"icon"} />}
                            {SSNError !== "" && <div className="danger-color">{SSNError}</div>}
                        </div>
                    </div>
                    <div className={CustomError && Birthdate === "" || Birthdate === null ? "form-group error" : "form-group"}>
                        <label>Date of Birth *</label>
                        <div className="form-groupfiled">
                            <DatePicker 
                                selected={Birthdate} 
                                onChange={(date) => setBirthdate(date)} 
                                peekNextMonth 
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter Date of Birth"
                                maxDate={newDate}
                            />
                            {Birthdate !== "" && Birthdate !== null && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && (Birthdate === "" || Birthdate === null) && <CustomMendotoryMsg value={Birthdate} label={"Birth date"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="termscondtaceptsection">
                        <div className="form-group-checkbox">
                            <input type="checkbox" id="emailcheck" onChange={(e) => setEmailCheckValid(e.target.checked)} checked={emailCheckValid} />
                            <label htmlFor="emailcheck">Preferred Method: Mailed Cheque</label>
                        </div>
                    </div>
                </div>
                {!emailCheckValid && (
                    <>
                    <div className="rowcustom">
                    <div className="form-group">
                        <label>Bank Name {(AccountNumber !== "" || RoutingNumber !== "")&& "*"}</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={bankname} onChange={(e) => setBankName(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Bank Name" />
                            {bankname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {bankname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt="icon" />}
                {((RoutingNumber !== "" || AccountNumber !== "") && bankname === "") && 
                    <CustomMendotoryMsg value={bankname} label={"Bank Name"} />
                }

                        </div>
                    </div>
                    </div>
                    <div className="rowcustom rowcustom-col-2">
                    
                    <div className="form-group">
                        <label>Routing Number {bankname !== "" && "*"}</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={RoutingNumber} onChange={(e) => setRoutingNumber(e.target.value.trim())} 
                             onInput={handleNumericInput}
                            placeholder="Enter Routing Number" autoComplete="off"/>
                            {RoutingNumber !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {(bankname !== "") && 
                    <CustomMendotoryMsg value={RoutingNumber} label={"Routing Number"} />
                }
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Account Number {bankname !== "" && "*"}</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={AccountNumber} onChange={(e) => setAccountNumber(e.target.value.trim())} 
                             onInput={handleNumericInput}
                            placeholder="Enter Account Number" autoComplete="off"/>
                            {AccountNumber !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {(bankname !== "") && <CustomMendotoryMsg value={AccountNumber} label={"Account Number"} />}
                        </div>
                    </div>
                   </div>
                    </>
                )}
                
                <div className="rowcustom">
                    <div className="form-group">
                        <label>Address *</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Address" />
                            {address !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && address === "" && <CustomMendotoryMsg value={address} label={"Address"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="form-group">
                        <label>Billing Address 1</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={BillingAddressOne} onChange={(e) => setBillingAddressOne(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Billing Address 1" />
                            {BillingAddressOne !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label>Billing Address 2</label>
                        <div onClick={copyBilling1} style={{cursor:'pointer',color:'blue',textAlign:"end"}}>Same as Billing Address 1</div>
                    </div>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control"  value={BillingAddressTwo} onChange={(e) => setBillingAddressTwo(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Billing Address 2" />
                            {BillingAddressTwo !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        </div>
                    </div>
                </div>
                {/* <div className="rowcustom rowcustom-col-3">
                    <div className="form-group">
                        <label>Paypal</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={Paypal} onChange={(e) => setPaypal(e.target.value.trim())} placeholder="Paypal" />
                            {Paypal !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Cashapp</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={Cashapp} onChange={(e) => setCashapp(e.target.value.trim())} placeholder="Cashapp" />
                            {Cashapp !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Venmo</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={Venmo} onChange={(e) => setVenmo(e.target.value.trim())} placeholder="Enter Venmo" />
                            {Venmo !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        </div>
                    </div>
                </div> */}
                <div className="rowcustom">
                    <div className="termscondtaceptsection">
                        <div className="form-group-checkbox">
                            <input type="checkbox" id="termsconditions" onChange={(e) => SelectTermsConditions(e)} checked={updateTermsCondtions} />
                            <label htmlFor="termsconditions">I accept terms & conditions <Link to={TERMS_CONDITIONS} onClick={() => closeSidebar("checkTermsfile")}>View terms of use</Link></label>
                        </div>
                        {errorTermsCondtions !== "" && <div className="danger-color">{errorTermsCondtions}</div>}
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="form-group">
                        <Button className="btn formcomnbtn" 
                        // disabled={(Birthdate !== "" && SSN !== "" && address !== "" && SelectCountry !== "" && SelectState !== "" && SelectCity !== "" && zip !== "" && updateTermsCondtions !== false || (bankname!==""&& (RoutingNumber === "" || AccountNumber === ""))|| ((RoutingNumber !== "" || AccountNumber !== "") && bankname === "")) ? false : true} 
                        disabled={
                           (bankname !== "" && (RoutingNumber === "" || AccountNumber === "")) ||
                           ((RoutingNumber !== "" || AccountNumber !== "") && bankname === "") ||
                            (Birthdate === "" || SSN === "" || address === "" || SelectCountry === "" || SelectState === "" || SelectCity === "" || zip === "" || updateTermsCondtions === false)
                        }onClick={() => signupsubmit()}>Submit</Button>
                        <Button className="btn formcomnbackbtn" onClick={() => setSignupSetps("personaldetails")}>Back</Button>
                    </div>
                </div>
            </div>) : (<div className="paymentformsWraps signupfields">
                {/* <div className="rowcustom rowcustom-col-2">
                    <div className={(errorEmail !== "" && EmailRegex.test(email) === false) ? "form-group error" : "form-group"}>
                        <label>Email *</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} maxLength="100" placeholder="Enter Email" />
                            {EmailRegex.test(email) === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {EmailRegex.test(email) === false && <div className="danger-color">{errorEmail}</div>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <div className="form-groupfiled">
                            <select className="form-control" value={SelectCountry} defaultValue={SelectCountry} onChange={(e) => SelectCountryChange(e)}>
                                <option value="">Select Country</option>
                                {Country.getAllCountries().map((Country, index) => {
                                    return(<option  value={Country.isoCode} key={index}>{Country?.name}</option>)
                                })}
                            </select>
                            {errorCountry !== "" && <div className="danger-color">{errorCountry}</div>}
                        </div>
                    </div>
                </div> */}
                <div className="rowcustom rowcustom-col-2">
                    <div className="form-group">
                        <label>Id Type</label>
                        <div className="form-groupfiled">
                            <select className="form-control" onChange={(e) => SelectIdTypeOnchange(e)}>
                                <option value="">Select Id Type</option>
                                <option value="Passport">Passport</option>
                                <option value="id_card">Id Card</option>
                                <option value="driving_license">Driving License</option>
                                <option value="residence_permit">Residence Permit</option>
                            </select>
                        </div>
                        {errorSelectIdType !== "" && <div className="danger-color">{errorSelectIdType}</div>}
                    </div>
                    <div className="form-group">
                        <label>Face Image</label>
                        <div className="form-groupfiled">
                            <div className="capturebtnwrap">
                                <Button className="capturebtn" onClick={() => setCameraOpen(!cameraOpen)}>
                                    <Image src={CameraIcon} alt="" />
                                    Upload Image
                                </Button>
                            </div>
                            {PreviewImageTitle?.face_image_title !== "" && (<div className="uplodfiles">
                                <img src={CloseIcon} onClick={() => SelectFileRemove('face_image')} alt="close" />
                                <span>{PreviewImageTitle?.face_image_title}</span>
                            </div>)}
                        </div>
                        {errorImgSrc !== "" && <div className="danger-color">{errorImgSrc}</div>}
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    {/* <div className={CustomError && UploadedProfileId === "" ? "form-group error" : "form-group"}>
                        <label>Front Image</label>
                        <div className="form-groupfiled">
                            <label className="uploadfileinput">
                                Front Image
                                <svg className="hffSvs" width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g className="d9Pp3H"><rect x="6" y="1" width="3" height="13"></rect><rect x="1" y="6" width="13" height="3"></rect></g></g></svg>
                                <input type="file" className="form-control" onChange={(e) => UploadFile(e, "front", "front_image")} accept=".png" />
                            </label>
                            {SelectedUploaderFile !== "" && (<div className="uplodfiles">
                                <img src={CloseIcon} onClick={() => SelectFileRemove()} alt="close" />
                                <span>{SelectedUploaderFile?.name}</span>
                                {FileUploadLoader && (<div className="loaderFile"></div>)}
                            </div>)}
                            {UploadedProfileId !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {errorFrontImage !== "" && <div className="danger-color">{errorFrontImage}</div>}
                        </div>
                    </div> */}
                    <div className="form-group">
                        <label>Front Image</label>
                        <div className="form-groupfiled">
                            <label className="btn Browsebtn">
                                <input type="file" onChange={(e) => UploadFile(e, "front", "front_image")} /> 
                                <Image src={IDcard} alt="" />
                                Upload Image
                            </label>
                            {PreviewImageTitle?.front_image_title !== "" && (<div className="uplodfiles">
                                <img src={CloseIcon} onClick={() => SelectFileRemove('front_image')} alt="close" />
                                <span>{PreviewImageTitle?.front_image_title}</span>
                            </div>)}
                        </div>
                        {errorFrontImage !== "" && <div className="danger-color">{errorFrontImage}</div>}
                    </div>
                    <div className="form-group">
                        <label>Back Image</label>
                        <div className="form-groupfiled">
                            <label className="btn Browsebtn">
                                <input type="file" onChange={(e) => UploadFile(e, "back", "back_image")} /> 
                                <Image src={IDcard} alt="" />
                                Upload Image
                            </label>
                            {PreviewImageTitle?.back_image_title !== "" && (<div className="uplodfiles">
                                <img src={CloseIcon} onClick={() => SelectFileRemove('back_image')} alt="close" />
                                <span>{PreviewImageTitle?.back_image_title}</span>
                            </div>)}
                        </div>
                        {errorBackImage !== "" && <div className="danger-color">{errorBackImage}</div>}
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="form-group">
                        <Button className="btn formcomnbtn" onClick={() => photoIDVerification()}>Submit</Button>
                    </div>
                </div>
                {FileUploadLoader && (<div className="loaderfilewrapper">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    Please wait file is uploading
                </div>)}
            </div>)}
            <div className="prvidethiswhy">
                <img src={SweepsCoinsImage} alt="Sweeps Coins" />
                <div className="prvidethiswhytoggleinfo">
                    <div onClick={() => setProvideContent(!ProvideContent)}>Why do I need to provide this?</div>
                    {ProvideContent && (<p>Sweeps Coins is an authorized sponsor of promotional sweepstakes games.  In order to comply with local and federal laws, including anti-money laundering laws, sweepstakes laws, and tax laws, we are required to collect certain personal information from sweepstakes participants before we can award prizes.  The requirement that we collect such information is also spelled out in our 
                        <Link to={TERMS_CONDITIONS} onClick={() => closeSidebar("checkTermsfile")} >Terms & Conditions</Link> and <Link to={PROMOTIONAL_RULES} onClick={() => closeSidebar("checkTermsfile")}>Promotional Rules</Link>.  We value your privacy, and we will not use your personal information for any unauthorized reasons.  Please see our <Link to={PRIVACY_POLICY} onClick={() => closeSidebar("checkTermsfile")}>Privacy Policy</Link> for further information on how we protect your private information.</p>)}
                </div>
            </div>
        </div>
        
        </div>
        {cameraOpen && <CameraTakePhoto UploadFile={UploadFile} imgSrc={imgSrc} setImgSrc={setImgSrc} setCameraOpen={setCameraOpen} />}
        {kycVerificationLoader && (<div className="loader_Wrapper">
            <div className="loader_main">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <h4>"We're verifying your identity, <br /> this process can take up to 60 seconds</h4>
            </div>
        </div>)}
    </React.Fragment>);
}

export default SignUpSidebar;