/* eslint-disable */
import React, { useState, useEffect } from "react";
import { USER_CREDIT_REQUEST_API_URL,PROFILE_UPDATE_API_URL } from "../../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { CallLogoutUser, IsSiteIsBlockCheck, getGeoCoplyMessage, getRegioLcTime, getOverCallingGeoLocation, getGeoCoplyLocation, getSpoofingDetection, accountNavigate } from "../../../redux/actions";
import EmailCheckIcon from "../../../assets/img/email-payment.png";
import CryptoImg from "../../../assets/img/Crypto.png";
import CardIcon from "../../../assets/img/credit-card-payment.png";
import BankingIcon from "../../../assets/img/bank-payment.png";
import { updateUser } from "../../../redux/actions";


const RedeemCreditsModal = (props) => {
    const dispatch = useDispatch();
    const { RedeemCrditModalState, setRedeemCrditModalState, accessToken, RequestProcessModel, setRequestProcessModel } = props;
    const [ creditAmount, setCreditAmount ] = useState("");
    const exceptThisSymbols = ["e", "E", "+", "-"];
    const {UniqueBrowserId, profiledata } = useSelector((state) => state.allReducers);
    const [ SelectPaymentMethod, setSelectPaymentMethod ] = useState("");
    const [ SelectMainPaymentMethod, setSelectMainPaymentMethod ] = useState("");
    const [ ErrorPaymentSelect, setErrorPaymentSelect ] = useState({
        type: "",
        message: "",
        function: ""
    });
    
    // error credit ammount
    const [ errorCreditAmount, setErrorCreditAmount ] = useState("");

    useEffect(() => {
        const myInput = document.getElementById("redeemInputBox");

        myInput?.addEventListener("focus", function() {
            myInput.placeholder = "";
        });

        myInput?.addEventListener("blur", function() {
            myInput.placeholder = "Number of credits to redeem";
        });
    }, []);
    const submitRedeem = async () => {
        if(SelectPaymentMethod === "") {
            toast.error("Please select payment method");
        };
        if((creditAmount !== "" && creditAmount.toString() !== "0") && SelectMainPaymentMethod !== "") {
            dispatch(getRegioLcTime(""));
            dispatch(getOverCallingGeoLocation(true));
            GetGeoLicenceFunction("credit-request"); 
            document.getElementById("pageisLoading").style.display = "flex";
        } else {
            if(creditAmount === "") {
                setErrorCreditAmount("Redeem amount cannot be empty");
            } else if(creditAmount.toString() === "0") {
                setErrorCreditAmount("Please enter valid amount");
            } else {
                setErrorCreditAmount("");
            }
        };
    };
    
    const GetGeoLicenceFunction = async (url) => {
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${UniqueBrowserId}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            if(result?.status === 200) {
            GetGeoLocate(result?.data?.data?.license, url);
            dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(UniqueBrowserId);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // callback call
    const callGeoTimeApi = async (re_geolocation) => {
        if(re_geolocation !== null) {
            var reGeoLcTime = re_geolocation * 1000;
            localStorage.setItem("reGeoLcTime", reGeoLcTime.toString());
            var currentTimeMillis = Date.now();
            var currentTimeString = currentTimeMillis.toString();
            localStorage.setItem("reGeoLcTimeStamp", currentTimeString);
            // setTimeout(() => {
            //     GetGeoLicenceFunction("Periodic");
            // }, reGeoLcTime);
        }
    };
    
    // location Permissions api
    const LocationPermission = async (text) => {
        console.log("Redeem Permission");
        const jsonData = JSON.stringify({ 
        "id": UniqueBrowserId,
        "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            if(result?.status === 200) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            if(result?.data?.data?.status === true) {
                dispatch(getOverCallingGeoLocation(false));
                submitRedeemGeoFunction();
                // success code
                localStorage.removeItem("starttime");
                localStorage.removeItem("targettime");
                dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
            }
            dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
            if(result?.data?.data?.status === false) {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                if(result?.data?.data?.spoofing_detection != null){
                    // add spoofing detection popup
                    console.log("spoofing detection");
                }
            }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
            
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };


    // user credit request api function
    const submitRedeemGeoFunction = () => {
        if((creditAmount !== "" && creditAmount.toString() !== "0") && SelectMainPaymentMethod !== "") {
            window.axios.get(`${USER_CREDIT_REQUEST_API_URL}${RedeemCrditModalState?.RedeemData?.id}/${SelectMainPaymentMethod}/${creditAmount}` , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                if(result?.data) {
                    document.getElementById("pageisLoading").style.display = "none";
                    setRedeemCrditModalState({...RedeemCrditModalState, open: false});
                    setRequestProcessModel({...RequestProcessModel, open: true, data: result?.data?.data})
                    setCreditAmount("");
                    setErrorCreditAmount("");
                    toast.success(result.data.msg);
                }
            }).catch(function (result) {
                document.getElementById("pageisLoading").style.display = "none";
                toast.error(result.response.data.error);
                if(result?.response?.status === 403) {
                    dispatch(CallLogoutUser());
                    localStorage.removeItem("access_tokens");
                } else if(result?.response?.status === 401) {
                    setRedeemCrditModalState({...RedeemCrditModalState, open: false});
                    if(result?.response?.data?.payment === "ACH" || result?.response?.data?.payment === "CHEQUE") {
                        dispatch(accountNavigate("my-account"));
                    } else if(result?.response?.data?.payment === "OCT") {
                        dispatch(accountNavigate("card-details"))
                    };
                };
            });
        } else {
            if(creditAmount === "") {
                setErrorCreditAmount("Redeem amount cannot be empty");
            } else if(creditAmount.toString() === "0") {
                setErrorCreditAmount("Please enter valid amount");
            } else {
                setErrorCreditAmount("");
            }
        }            
    };

    // valid ammount
    const ValidCreditCard = (value) => {
        if(value === "") {
            setErrorCreditAmount("Redeem amount cannot be empty");
        } else if(value.toString() === "0") {
            setErrorCreditAmount("Please enter valid amount");
        } else {
            setErrorCreditAmount("");
        }
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    var validate = function(e, decimalPlaces) {
        // Get the input value
        let value = e.target.value;
            
        // Define a regular expression to match the format you want
        const regex = new RegExp(`^-?\\d+(\\.\\d{0,${decimalPlaces}})?$`);
        
        // Test the input value against the regular expression
        if (!regex.test(value)) {
            // If it doesn't match, remove any invalid characters
            value = value.replace(/[^0-9.-]/g, '');
            
            // Ensure only two decimal places
            const parts = value.split('.');
            if (parts[1] && parts[1].length > decimalPlaces) {
                parts[1] = parts[1].substring(0, decimalPlaces);
                value = parts.join('.');
            }
            
            // Update the input value
            e.target.value = value;
        }
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputredeem")) {
            document.activeElement.blur();
        }
    });

    const UpdatePaymentMethod  =(value) =>{
        const jsonData = JSON.stringify({
            first_name: profiledata?.first_name,
            last_name: profiledata?.last_name,
            phone: profiledata?.phone,
            routing_number: profiledata?.routing_number,
            account_number: profiledata?.account_number,
            billing_address1: profiledata?.billing_address1,
            billing_address2: profiledata?.billing_address2,
            is_email_check: profiledata?.is_email_check,
            bank_name: profiledata?.bank_name,
            wallet_address: profiledata?.wallet_address,
            payment_method: value,
          });
          window?.axios
            ?.post(PROFILE_UPDATE_API_URL, jsonData, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + accessToken,
              },
            })
            .then(function (result) {
              dispatch(updateUser(result?.data?.data));
            })
            .catch(function (result) {
              if (result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
              }
              setLoader(false);
            });
  
    }
    // console.log("profiledata",profiledata?.wallet_address)
    const SelectPayment = (value) => {
        setSelectPaymentMethod(value);
        if(value === "ACH") {
            if((profiledata?.account_number !== null || profiledata?.routing_number !== null) === true){
                // if(profiledata?.paynote_verified){
                //     setErrorPaymentSelect({...ErrorPaymentSelect, type: "", message: ""});
                //     setSelectMainPaymentMethod(value);
                //     UpdatePaymentMethod(value)

                    
                // }else{
                //     setErrorPaymentSelect({...ErrorPaymentSelect, type: "ACH", message: "Please verify your Account Number to ensure accuracy"});
                //     setSelectMainPaymentMethod("");
                // }
                    setErrorPaymentSelect({...ErrorPaymentSelect, type: "", message: ""});
                    setSelectMainPaymentMethod(value);
                    UpdatePaymentMethod(value)
            } else {
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "ACH", message: "Please enter your bank details, including the Bank Name, Account Number, and Routing Number"});
                setSelectMainPaymentMethod("");
            };
        } else if(value === "OCT") {
            if(profiledata?.card_available === true) {
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "", message: ""});
                setSelectMainPaymentMethod(value);
                UpdatePaymentMethod(value)

            } else {
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "OCT", message: "Kindly provide your card details."});
                setSelectMainPaymentMethod("");
            };
        } else if(value === "CHEQUE") {
            if((profiledata?.billing_address1 !== null && profiledata?.billing_address1 !== "") || (profiledata?.billing_address2 !== null && profiledata?.billing_address2 !== "")) {
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "", message: ""});
                setSelectMainPaymentMethod(value);
                UpdatePaymentMethod(value)

            } else {
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "CHEQUE", message: "Please enter the billing address 1 or billing address 2. "});
                setSelectMainPaymentMethod("");
            };
        }else if(value ==="CRYPTO"){
            if(profiledata?.wallet_address !==null && profiledata?.wallet_address !==""){
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "", message: ""});
                setSelectMainPaymentMethod(value);
                UpdatePaymentMethod(value)

            }else{
                setErrorPaymentSelect({...ErrorPaymentSelect, type: "CRYPTO", message: "Please enter crypto wallet address"});
                setSelectMainPaymentMethod("");
            }

        }
    };
    

    // setup payment
    const SetupPayment = () => {
        if(ErrorPaymentSelect?.type === "ACH" || ErrorPaymentSelect?.type === "CHEQUE" || ErrorPaymentSelect?.type === "CRYPTO") {
            dispatch(accountNavigate("withdrawal-methods"));
            setRedeemCrditModalState({...RedeemCrditModalState, open: false});
        } else if(ErrorPaymentSelect?.type === "OCT") {
            dispatch(accountNavigate("card-details"))
            setRedeemCrditModalState({...RedeemCrditModalState, open: false});
        };
    };

    const onChange = (e) =>{
        if(e.target.value?.length<=8){
            if(e.target.value <= 0){
                e.target.value = "";
            }
        }else{
            e.target.value = creditAmount;
        }
        // e.target.value?.length<8 ? setCreditAmount(parseInt(e.target.value)>0?e.target.value:creditAmount)
        setCreditAmount(e.target.value)
    }
    
    return(<div className="RedeemCreditModalMain">
        <div className="closebtn" onClick={() => setRedeemCrditModalState({...RedeemCrditModalState, open: false})}>
            <svg preserveAspectRatio="xMidYMid meet" data-bbox="29.6 26 148 148" xmlns="http://www.w3.org/2000/svg" viewBox="29.6 26 148 148" role="presentation" aria-hidden="true">
                <g><path d="M177.6 147.3L130.3 100l47.3-47.3L150.9 26l-47.3 47.3L56.3 26 29.6 52.7 76.9 100l-47.3 47.3L56.3 174l47.3-47.3 47.3 47.3 26.7-26.7z"></path></g>
            </svg>    
        </div>
        <h1>{RedeemCrditModalState?.title}</h1>
        <div className="redeempics">
            <img src={RedeemCrditModalState?.RedeemData?.product_img_path} alt={"redeempics_" + RedeemCrditModalState?.RedeemData?.id} /> 
            <div className="reedem_wrapper">
                <h4>Select Payment Method</h4>
                <div className="choose_card_method">
                    <label htmlFor="ACH" className={SelectPaymentMethod === "ACH" && (profiledata?.account_number !== null || profiledata?.routing_number !== null) === true ? "selectPayment active" : SelectPaymentMethod === "ACH" && (profiledata?.account_number !== null && profiledata?.routing_number !== null) === false ? "selectPayment error" : "selectPayment"}>
                        <input type="radio" name="fav_payment" id="ACH" value="ACH" onChange={(e) => SelectPayment(e.target.value)} />
                        <img src={BankingIcon} alt="BankingIcon"/>
                        Banking 
                    </label>
                    {/* <label htmlFor="OCT" className={SelectPaymentMethod === "OCT" && profiledata?.card_available === true ? "selectPayment active" : SelectPaymentMethod === "OCT" && profiledata?.card_available === false ? "selectPayment error" : "selectPayment"}>
                        <input type="radio" name="fav_payment" id="OCT" value="OCT" onChange={(e) => SelectPayment(e.target.value)} />
                        <img src={CardIcon} alt="CardIcon"/>
                        Card
                    </label> */}
                    <label htmlFor="CHEQUE" className={SelectPaymentMethod === "CHEQUE" && ((profiledata?.billing_address1 !== null && profiledata?.billing_address1 !== "") || (profiledata?.billing_address2 !== null && profiledata?.billing_address2 !== "")) === true ? "selectPayment active" : SelectPaymentMethod === "CHEQUE" && ((profiledata?.billing_address1 !== null && profiledata?.billing_address1 !== "") || (profiledata?.billing_address2 !== null && profiledata?.billing_address2 !== "")) === false ? "selectPayment error" : "selectPayment"}>
                        <input type="radio" name="fav_payment" id="CHEQUE" value="CHEQUE" onChange={(e) => SelectPayment(e.target.value)} />
                        <img src={EmailCheckIcon} alt="EmailCheckIcon"/>
                        Mailed Cheque
                    </label>
                    <label htmlFor="CRYPTO" className={SelectPaymentMethod === "CRYPTO" && ((profiledata?.wallet_address !== null && profiledata?.wallet_address !== "") ) === true ? "selectPayment active" : SelectPaymentMethod === "CRYPTO" && ((profiledata?.wallet_address !== null && profiledata?.wallet_address !== "")) === false ? "selectPayment error" : "selectPayment"}>
                        <input type="radio" name="fav_payment" id="CRYPTO" value="CRYPTO" onChange={(e) => SelectPayment(e.target.value)} />
                        <img src={CryptoImg} alt="CryptoImg"/>
                        Crypto
                    </label>
                </div>
                {ErrorPaymentSelect?.type !== "" && (<div className="medatoryErrorMessage">
                    {ErrorPaymentSelect?.message}
                    <button className="btn" onClick={() => SetupPayment()}>Setup</button>
                </div>)}
                <div className="redeemcreditform">
                    <input 
                        type="number" 
                        id="redeemInputBox"
                        className="form-control inputredeem" 
                        value={creditAmount} 
                        onKeyUp={(e) => ValidCreditCard(e.target.value)}
                        onChange={onChange}
                        min="0" 
                        onKeyPress={preventMinus} 
                        onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) && e.preventDefault()
                        }
                        onInput={(e) => validate(e, 2)}
                        placeholder="Number of credits to redeem"
                    />
                    {errorCreditAmount !== "" && (<div className="danger-colorset">{errorCreditAmount}</div>)}
                    <button className="btn" onClick={() => submitRedeem()} >Submit</button>
                </div>
            </div>
        </div>
    </div>)
}

export default RedeemCreditsModal;