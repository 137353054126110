/* eslint-disable */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MY_ACCOUNT, LOGOUT_API_URL, HOME_URL } from "../../Shared/constant";
import { accountNavigate, getAccessToken, checkLogin, getUser } from "../../../redux/actions/index";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CommonDropdown = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { setDropdwon, accessToken } = props;

    // Member dashboard link route
    const MemberDashboard = () => {
        setTimeout(() => {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }, 200);
        dispatch(accountNavigate("my-books"));
        setDropdwon(false);
    };

    // my account link route
    const MyAccountLink = () => {
        setTimeout(() => {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }, 200);
        dispatch(accountNavigate("my-account"));
        setDropdwon(false);
    };

    // logout function
    const Logout = () => {
        window.axios.get(LOGOUT_API_URL, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setTimeout(() => {
                var element = document.getElementById("responsiveSidebarMain");
                element.classList.remove("active");
                Navigate(HOME_URL)
            }, 200);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("access_tokens");
            localStorage.setItem("SAVE_CARD", "FALSE");
            dispatch(getAccessToken(""));
            dispatch(checkLogin("no"));
            toast.success(result.data.msg);
            setDropdwon(false);
        })  
        .catch(function (result) {
            // toast.error(result.response.data.detail);
            setDropdwon(false);
            // if(result.response.data.detail === "Token expired.") {
                AccessTokenCheckLogout();
            // } else {
            //     AccessTokenCheckLogout();
            // }
        });
    };

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() => {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }, 200);
        setTimeout(() =>  Navigate(HOME_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        localStorage.setItem("SAVE_CARD", "FALSE");
        dispatch(getUser(""));
    };

    return(<div className="CommonDropdownWraps">
        <ul>
            <li>
                <Link to={MY_ACCOUNT} onClick={() => MemberDashboard()}>Member Dashboard</Link>
            </li>
            <li>
                <Link to={MY_ACCOUNT} onClick={() => MyAccountLink()}>My Account</Link>
            </li>
            <li>
                <a  onClick={() => Logout()} >Logout</a>
            </li>
        </ul>
    </div>);
}

export default CommonDropdown;