/*eslint-disable */
import React, { useState, useEffect } from "react";
import Countries from "./CountryCodes.json";
import CaretDown from "../../../assets/img/caret-down.svg";

const CountryMobileInput = (props) => {
    const { setMobileNo, MobileNo, id } = props;
    const [ SelectFlag, setSelectFlag ] = useState("US");
    const [ countryselect, setCountryselect] = useState("");
    const FlagsUrl = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";

    useEffect(() => {
        Countries.filter((elm) => {
            if(elm?.phone_code === parseInt(MobileNo?.countrycode)) {
                return setSelectFlag(elm?.country_code);
            }
        })
    }, [ MobileNo ]);

    // select country code & image
    const SelectCountryCode = (flags) => {
        setSelectFlag(flags.country_code);
        setMobileNo({...MobileNo, countrycode: flags.phone_code});
        setCountryselect("");
        document.getElementById(id)?.classList?.remove("active");
    };

    // open cuntry dropdwon function
    const OpenCountryDrop = (e) => {
        e.preventDefault();
        document.getElementById(id)?.classList?.toggle("active");
    }

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    // onChange effect 
    const OnchangeNoGet = (e) => {
        if(e.target.value.length <= 10) {
            setMobileNo({...MobileNo, number: e.target.value})
        }
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return (<div className="selectNoInputField">
        <div className="inputBoxSelect">
            <button className="selectEmojis" onClick={(e) => OpenCountryDrop(e)}>
                {SelectFlag !== "" && <img src={FlagsUrl + SelectFlag + ".svg"} className="flagsimg" alt="call image" />}
                <div className="countselet">+{MobileNo.countrycode}</div>
                <img src={CaretDown} className="arrowdownimg" alt="caret down" />
            </button>
            <div className="flagsDropdown" id={id}>
                <ul>
                    {Countries.filter((elm) => {
                        if(countryselect === "") {
                            return elm;
                        } else if(elm?.country_en.toLowerCase()?.includes(countryselect && countryselect?.toLowerCase())) {
                            return elm;
                        } 
                    }).map((flags, index) => {
                    return(<li key={index} onClick={() => SelectCountryCode(flags)}>
                        <img src={FlagsUrl + flags.country_code + ".svg"} />
                        <span>{flags?.country_en} (+{flags.phone_code})</span>
                    </li>)
                    })}
                </ul>
                <input type="text" id="flagsearchBox" value={countryselect} onChange={(e) => setCountryselect(e.target.value)} placeholder="Search Country..." />
            </div>
        </div>
        <input 
            type="number" 
            className="inputBoxs" 
            value={MobileNo.number}
            placeholder="Enter Phone Number"
            onChange={(e) => OnchangeNoGet(e)}
            onKeyPress={(e) => preventMinus(e)}
            min="0"
            onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
            }
        />
    </div>)
}

export default CountryMobileInput;