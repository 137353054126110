import React, { useState } from "react";
import EmailCheckIcon from "../../assets/img/email-payment.png";
import CryptoImg from "../../assets/img/Crypto.png";
import BankingIcon from "../../assets/img/bank-payment.png";
import { Button, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import SucessIcon from "../../assets/img/success.svg";
import { toast } from "react-toastify";
import {
  VERIFY_ACCOUNT_DETAILS,
  PROFILE_UPDATE_API_URL,
} from "../Shared/constant";
import { updateUser, CallLogoutUser, getUser } from "../../redux/actions";
import Verify from "../../assets/img/verify.png";
import InfoIcon from "../../assets/img/information-button.png";

const Withdrawalmethods = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { profiledata, accessToken } = useSelector(
    (state) => state.allReducers
  );

  const getDefaultMethod = () => {
    switch (profiledata?.payment_method) {
      case "CRYPTO":
        return "Crypto";
      case "ACH":
        return "Banking";
      case "CHEQUE":
        return "Mail Check";
      default:
        break; // Default to Banking if no method matches
    }
  };

  //   const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(getDefaultMethod());

  const [RoutingNumber, setRoutingNumber] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [bankname, setBankName] = useState("");
  const [cryptoaddress, setCryptoAddress] = useState("");
  const [BillingAddressOne, setBillingAddressOne] = useState("");
  const [BillingAddressTwo, setBillingAddressTwo] = useState("");
  const [errorBillingAddressOne, setErrorBillingAddressOne] = useState("");
  const [errorBillingAddressTwo, setErrorBillingAddressTwo] = useState("");
  const [errorCryptoAddress, setErrorCryptoAddress] = useState("");
  const [banknameerror, setBankNameError] = useState("");
  const [accountnameerror, setAccountNameError] = useState("");
  const [routingnumbererror, setRoutingNumberError] = useState("");
  const [StoreSameAs, setStoreSameAs] = useState(false);
  const [emailCheckValid, setEmailCheckValid] = useState(
    profiledata?.is_email_check === null ||
      profiledata?.is_email_check === false
      ? false
      : profiledata?.is_email_check
  );

  useEffect(() => {
    setRoutingNumber(
      profiledata?.routing_number === null || profiledata?.routing_number === ""
        ? ""
        : profiledata?.routing_number
    );
    setAccountNumber(
      profiledata?.account_number === null || profiledata?.account_number === ""
        ? ""
        : profiledata?.account_number
    );
    setBankName(
      profiledata?.bank_name === null || profiledata?.bank_name === ""
        ? ""
        : profiledata?.bank_name
    );
    setCryptoAddress(
      profiledata?.wallet_address === null || profiledata?.wallet_address === ""
        ? ""
        : profiledata?.wallet_address
    );
    setBillingAddressOne(
      profiledata?.billing_address1 === null ||
        profiledata?.billing_address1 === ""
        ? ""
        : profiledata?.billing_address1
    );
    setBillingAddressTwo(
      profiledata?.billing_address2 === null ||
        profiledata?.billing_address2 === ""
        ? ""
        : profiledata?.billing_address2
    );
  }, [profiledata]);
  const checkSame = (e) => {
    setStoreSameAs(e.target.checked);
    if (e.target.checked) {
      setBillingAddressTwo(BillingAddressOne);
      setErrorBillingAddressTwo("");
    }
  };

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
    setErrorBillingAddressTwo("");
    setErrorBillingAddressOne("");
    setErrorCryptoAddress("");
    setBankNameError("");
    setAccountNameError("");
    setRoutingNumberError("");
  };

  const EmptySpaceFieldValid = (e) => {
    if (e.target.value.length === 0 && e.which === 32) {
      return e.preventDefault();
    }
  };
  const SaveData = (type) => {
    if (type === "Mail Check") {
      if (BillingAddressOne === "" || BillingAddressTwo === "") {
        if (BillingAddressOne === "") {
          setErrorBillingAddressOne("Billing Address 1 cannot be empty");
        }
        if (BillingAddressTwo === "") {
          setErrorBillingAddressTwo("Billing Address 2 cannot be empty");
        }
        return null;
      } else {
        setLoader(true);
        const jsonData = JSON.stringify({
          first_name: profiledata?.first_name,
          last_name: profiledata?.last_name,
          phone: profiledata?.phone,
          routing_number: profiledata?.routing_number,
          account_number: profiledata?.account_number,
          billing_address1: BillingAddressOne,
          billing_address2: BillingAddressTwo,
          is_email_check: true,
          bank_name: profiledata?.bank_name,
          wallet_address: profiledata?.wallet_address,
          payment_method: "CHEQUE",
        });
        window?.axios
          ?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setErrorBillingAddressOne("");
            setErrorBillingAddressTwo("");
            toast.success(result.data.msg);
            setLoader(false);
          })
          .catch(function (result) {
            toast.error(result.response.data.error);
            if (result?.response?.status === 403) {
              dispatch(CallLogoutUser());
              localStorage.removeItem("access_tokens");
            }
            setLoader(false);
          });
      }
    } else if (type === "Banking") {
      if (bankname === "" || RoutingNumber === "" || AccountNumber === "") {
        if (bankname === "") {
          setBankNameError("Bank Name cannot be empty");
        }
        if (AccountNumber === "") {
          setAccountNameError("Account Name cannot be empty");
        }
        if (RoutingNumber === "") {
          setRoutingNumberError("Routing Number cannot be empty");
        }
        return null;
      } else {
        setLoader(true);
        const jsonData = JSON.stringify({
          first_name: profiledata?.first_name,
          last_name: profiledata?.last_name,
          phone: profiledata?.phone,
          routing_number: RoutingNumber.trim(),
          account_number: AccountNumber.trim(),
          billing_address1: profiledata?.billing_address1,
          billing_address2: profiledata?.billing_address2,
          is_email_check: emailCheckValid,
          bank_name: bankname.trim(),
          wallet_address: profiledata?.wallet_address,
          payment_method: "ACH",
        });
        window?.axios
          ?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setErrorBillingAddressOne("");
            setErrorBillingAddressTwo("");
            toast.success(result.data.msg);
            setLoader(false);
          })
          .catch(function (result) {
            toast.error(result.response.data.error);
            if (result?.response?.status === 403) {
              dispatch(CallLogoutUser());
              localStorage.removeItem("access_tokens");
            }
            setLoader(false);
          });
      }
    } else if (type === "Crypto") {
      if (cryptoaddress === "") {
        if (cryptoaddress === "") {
          setErrorCryptoAddress("Crypto Address cannot be empty");
        }
        return null;
      } else {
        setLoader(true);
        const jsonData = JSON.stringify({
          first_name: profiledata?.first_name,
          last_name: profiledata?.last_name,
          phone: profiledata?.phone,
          routing_number: profiledata?.routing_number,
          account_number: profiledata?.account_number,
          billing_address1: profiledata?.billing_address1,
          billing_address2: profiledata?.billing_address2,
          is_email_check: emailCheckValid,
          bank_name: profiledata?.bank_name,
          wallet_address: cryptoaddress,
          payment_method: "CRYPTO",
        });
        window?.axios
          ?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setErrorBillingAddressOne("");
            setErrorBillingAddressTwo("");
            toast.success(result.data.msg);
            setLoader(false);
          })
          .catch(function (result) {
            toast.error(result.response.data.error);
            if (result?.response?.status === 403) {
              dispatch(CallLogoutUser());
              localStorage.removeItem("access_tokens");
            }
            setLoader(false);
          });
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const AccountVerificatataion = () => {
    setLoading(true);
    window?.axios
      ?.get(VERIFY_ACCOUNT_DETAILS, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(function (result) {
        if (result?.data?.data?.status === true) {
          toast.success(result?.data?.msg);
          dispatch(
            getUser({
              ...profiledata,
              paynote_verified: true,
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (result) {
        // toast.error(result?.response?.data?.messages);
        setLoading(false);
        const errorMessage = result?.response?.data?.messages;

        if (Array.isArray(errorMessage)) {
          errorMessage?.forEach((msg) => {
            toast?.error(msg);
          });
        } else if (typeof errorMessage === "string") {
          toast.error(errorMessage);
        }
        if (result?.response?.status === 403) {
          dispatch(CallLogoutUser());
          localStorage.removeItem("access_tokens");
        }
      });
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  return (
    <>
      <div className="payment-options">
        <h4>Withdrawal Methods :</h4>
        <div className="option_wrapper">
          <div
            className={`option-box`}
            onClick={() => handleMethodChange("Mail Check")}
          >
            <label className={`option`}>
              <input
                type="checkbox"
                checked={selectedMethod === "Mail Check"}
                id="Withdroal_checkbox"
              />
              <div
                className={`Name_wrapper 
          ${selectedMethod === "Mail Check" ? "selected" : ""}
          `}
              >
                <img
                  src={EmailCheckIcon}
                  alt="Mail Check"
                  className="option-img"
                />
                <span className="option-name">
                  Mailed Cheque
                  {selectedMethod === "Mail Check" ? <p>(Primary)</p> : null}
                </span>
              </div>
            </label>
          </div>

          <div
            className={`option-box`}
            onClick={() => handleMethodChange("Banking")}
          >
            <label className={`option`}>
              <input
                type="checkbox"
                checked={selectedMethod === "Banking"}
                id="Withdroal_checkbox"
              />
              <div
                className={`Name_wrapper  ${
                  selectedMethod === "Banking" ? "selected" : ""
                } `}
              >
                <img src={BankingIcon} alt="Banking" className="option-img" />
                <span className="option-name">
                  Banking
                  {selectedMethod === "Banking" ? <p>(Primary)</p> : null}
                </span>
              </div>
            </label>
          </div>

          <div
            className={`option-box`}
            onClick={() => handleMethodChange("Crypto")}
          >
            <label className={`option`}>
              <input
                type="checkbox"
                checked={selectedMethod === "Crypto"}
                id="Withdroal_checkbox"
              />
              <div
                className={`Name_wrapper ${
                  selectedMethod === "Crypto" ? "selected" : ""
                }`}
              >
                <img src={CryptoImg} alt="Crypto" className="option-img" />
                <span className="option-name">
                  Crypto
                  {selectedMethod === "Crypto" ? <p>(Primary)</p> : null}
                </span>
              </div>
            </label>
          </div>
        </div>

        <div>
          {selectedMethod === "Mail Check" && (
            <div className="method-section">
              <h3>Mailed Cheque Section</h3>
              <div className="rowcustom">
                <div className="form-group">
                  <label>Billing Address 1 *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={BillingAddressOne}
                      onChange={(e) => {
                        setBillingAddressOne(e.target.value);
                        setErrorBillingAddressOne("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Billing Address 1"
                    />
                    {BillingAddressOne !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {errorBillingAddressOne && (
                    <small className="text-danger">
                      {" "}
                      {errorBillingAddressOne}
                    </small>
                  )}
                </div>
              </div>
              <div className="rowcustom">
                <div className="termscondtaceptsection">
                  <div className="form-group-checkbox">
                    <input
                      type="checkbox"
                      id="StoreSameAs"
                      onChange={checkSame}
                      disabled={
                        BillingAddressOne == "" && BillingAddressTwo == ""
                          ? true
                          : false
                      }
                      checked={StoreSameAs}
                    />
                    <label htmlFor="StoreSameAs">
                      Billing address 1 Same Billing address 2{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="rowcustom">
                <div className="form-group">
                  <label>Billing Address 2 *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      disabled={StoreSameAs}
                      value={BillingAddressTwo}
                      onChange={(e) => {
                        setBillingAddressTwo(e.target.value);
                        setErrorBillingAddressTwo("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Billing Address 2"
                    />
                    {BillingAddressTwo !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {errorBillingAddressTwo && (
                    <small className="text-danger">
                      {" "}
                      {errorBillingAddressTwo}
                    </small>
                  )}
                </div>
              </div>
              <div className="Withdraw_Btn_Wrapper">
                <Button
                  className="btn updteinfobtn"
                  disabled={loader}
                  onClick={() => SaveData("Mail Check")}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {selectedMethod === "Banking" && (
            <div className="method-section">
              <div className="Verify_wrapper">
                <h3>Banking Section</h3>
                {/* {profiledata?.paynote_verified ? (
                  <div className="verified">
                    <span>Verified</span>
                    <div>
                      <img alt="verify_img" src={Verify} />
                    </div>
                  </div>
                ) : (
                  <div className="verify">
                    <button disabled={loading} onClick={AccountVerificatataion}>
                      Verify
                    </button>
                    <div className="passwordnotewrapper2">
                      <ol>
                        <p>
                          To verify or attach your account details, please save
                          your account information first.
                        </p>
                      </ol>
                      <Image src={InfoIcon} alt="info icon" />
                    </div>
                  </div>
                )} */}
              </div>
              <div className="rowcustom">
                <div className="form-group">
                  <div className="Verify_wrapper">
                    <label>Bank Name *</label>
                  </div>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={bankname}
                      onChange={(e) => {
                        setBankName(e.target.value);
                        setBankNameError("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Bank Name"
                    />
                    {bankname !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {banknameerror && (
                    <small className="text-danger">{banknameerror}</small>
                  )}
                </div>
              </div>
              <div className="rowcustom rowcustom-col-2">
                <div className="form-group">
                  <label>Routing Number *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={RoutingNumber}
                      onChange={(e) => {
                        setRoutingNumber(e.target.value);
                        setRoutingNumberError("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      onInput={handleNumericInput}
                      placeholder="Enter Routing Number"
                      autoComplete="off"
                    />
                    {RoutingNumber !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {routingnumbererror && (
                    <small className="text-danger">{routingnumbererror}</small>
                  )}
                </div>
                <div className="form-group">
                  <label>Account Number *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={AccountNumber}
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                        setAccountNameError("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      onInput={handleNumericInput}
                      placeholder="Enter Account Number"
                      autoComplete="off"
                    />
                    {AccountNumber !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {accountnameerror && (
                    <small className="text-danger">{accountnameerror}</small>
                  )}
                </div>
              </div>
              <div className="Withdraw_Btn_Wrapper">
                <Button
                  className="btn updteinfobtn"
                  disabled={loader}
                  onClick={() => SaveData("Banking")}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {selectedMethod === "Crypto" && (
            <div className="method-section">
              <h3>Crypto Section</h3>
              <div className="rowcustom">
                <div className="form-group">
                  <label>Crypto Wallet Address *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={cryptoaddress}
                      onChange={(e) => setCryptoAddress(e.target.value)}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Crypto Address"
                    />
                    {cryptoaddress !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {errorCryptoAddress && (
                    <small className="text-danger"> {errorCryptoAddress}</small>
                  )}
                </div>
              </div>
              <div className="Withdraw_Btn_Wrapper">
                <Button
                  className="btn updteinfobtn"
                  disabled={loader}
                  onClick={() => SaveData("Crypto")}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Withdrawalmethods;
